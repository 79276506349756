import "./main.pcss";

import App from "./App.svelte";

const app = new App({
  target: document.body,
  props: {
    // name: "world",
    // startDrag: "",
    // stopDrag: "test",
  },
});

export default app;
