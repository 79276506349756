<script>
    import {fade} from 'svelte/transition'

    let isOpen = false;
    let _class1 = "block px-2 py-1 white rounded hover:bg-gray-800";
    
    function toggleNav() { isOpen =!isOpen; }

</script>





<!-- <div class='flex flex-col h-full '>
    <header class="md:hidden">
        <h1 class="text-3xl text-red-600">HEADER </h1>
    </header>

    <div id='content' class='flex-1 mx-auto p-8'>
        <h1 class="text-3xl text-red-600">Startseite DE ED </h1>
        <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br />
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br />
        Ut enim ad minim veniam, quis nostrud exercitation ullamco.<br />
        Duis aute irure dolor in reprehenderit in voluptate velit esse. <br />
        Excepteur sint occaecat cupidatat non proident.
        <br><br>
        <button on:click={toggleNav}>toggle {isOpen}</button>

        </p>
    </div>

</div> -->