<!-- -------------------------------------------------------------------------------------------- -->

<script>
    import {fade} from 'svelte/transition'
    import {fly} from 'svelte/transition'
    import { quadInOut } from 'svelte/easing';
    import { download2Path } from '../Store/store.js';
    let anim1 = {delay: 100};
</script>


<!-- -------------------------------------------------------------------------------------------- -->



<div class="info-content" in:fly="{{duration: 500, opacity: 0.0, easing: quadInOut}}">
    <div class="container pt-24 sm:pt-16" in:fly="{{delay: 100, duration: 500, y: 50, opacity: 0.0, easing: quadInOut}}">



        <section id="Ueber">

            <div class="md:flex" in:fly="{{delay: 0}}">
                <div class="md:w-1/2">
                    <h1>About</h1>
                </div>
                <div class=":mdw-1/2">
                </div>
            </div>

            <div class="md:flex mb-8" in:fade="{{delay: 300}}" >
                <div class="md:w-1/2 md:pr-4">
                    <p class="text-white">With <i>Memory Loops</i>, Munich is creating a virtual memorial for the victims of National Socialism. Michaela Melián’s audio work of art comprises 300 German and 175 English audio tracks which can be found on a map drawn up by the artist and downloaded free of charge. Each track is a collage of voice(s) and music thematically tied to a place in the former ‘Capital of the Movement’.</p>
                    <!-- <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Weitere Informationen
                    </button><br>
                    <button>Presse und Archiv</button> -->
                    
                    <!-- <a class="bttn text-accentColor" href="/#weitere-informationen">Further Information</a><br> -->
                    <!-- <a class="bttn text-accentColor" href="/#presse-und-archiv">Press and Archive</a>  -->
                </div>
                <div class="md:w-1/2  md:pr-4">
                    <p>With her work <i>Memory Loops</i>, Michaela Melián has been awarded first prize in the art competition ‘Victims of National Socialism: New Forms of Remembering and Remembrance’ held by the Bavarian State Capital of Munich. The project is being carried out in cooperation with the Bavarian Broadcasting Corporation / Radio Play and Media Art Dept.</p>
                    <p><i>Memory Loops</i> is largely based on historical and recent material from victims of National Socialism and contemporary witnesses: witnesses of the discrimination, persecution and exclusion to which people in Munich were subjected under the NS regime. The reports and interviews have been transcribed and recorded by actresses and actors, with children reading the historical documents. These recordings have been embedded in an original musical score.</p>
                </div>
            </div>

        </section>

        <hr> <!-- --------------------------------------------------------------------------------- -->        
        
        <section id="weitere-informationen">

            <div class="md:flex" in:fly="{{delay: 0}}">
                <div class="md:w-1/2">
                    <h1>Further Information</h1>
                </div>
                <div class="md:w-1/2">
                </div>
            </div>

                <details  >
                    <summary><i>Memory Loops</i> – make your own personal compilation</summary>
                    <p>
                        The website memoryloops.net forms the central element of the artwork on which all audio tracks can be found on a map drawn by the artist.
                        <br>
                        Five of the German and one of the English audio tracks are one-hour <i>Memory Loops</i> that cover the whole city area with a focus on different subjects. The <i>Loop</i> in English can be found and downloaded <a href="/#/radioplay">here</a>.
                    </p>
                </details>

                <details >
                    <summary><i>Memory Loops</i> throughout the city of Munich</summary>
                
                    <p>In addition, signs around the city with URLs and telephone numbers at principal locations throughout Munich provide direct-dial access to the 61 audio tracks associated with a particular location at local rates. Should no internet link be available, individual audio tracks can still be called up on site at any time by phoning specific numbers.</p>
                
                    <ul class="downloads list-inside">
                        <li>
                            <a href="{$download2Path}ML_Schilder_Lageplan.pdf">Map of all signs</a>
                        </li>
                    </ul>
                </details>

                <details >
                    <summary>The Competition</summary>
                    <p>
                        With her work <i>Memory Loops</i>, Michaela Melián has been awarded first prize in the <a href="https://www.muenchen.de/rathaus/Stadtverwaltung/Kulturreferat/Bildende-Kunst/Kunst-im-oeffentlichen-Raum/Memory-Loops.html">art competition</a> ‘Victims of National Socialism: New Forms of Remembering and Remembrance’ held by the Bavarian State Capital of Munich. The project is being carried out in cooperation with the Bavarian Broadcasting Corporation / Radio Play and Media Art Dept.
                    </p>

                    <h4>From the jury’s explanation</h4>
                    <p>
                        “Ms. Melián’s work deals in an artistically sophisticated way with the fates of all the victims of National Socialism. At the same time, the perpetrators have not been excluded either. The most varied historical dimensions are analysed focussing on certain ‘crystallisation points’ in the City of Munich. Using archival material and contemporary witness statements, Melián artistically shapes voice-collages of the highest quality. Research and realisation entail broad institutional networking that already involves the integration of younger generations.
                    </p>
                    <p>
                        The artwork is decentralised and at the same time refers to historically important sites. By means of Memory Loops (voice-collages) on mobile phones and other sound storage media, Melián creates an all-pervasive form of remembering and remembrance. Melián’s concept of the decentral, the personal, the immaterial and the temporary, together with a high degree of audience participation, opens up avenues for knowledge and experience, underpinned by humanism and democracy, that avoid the interpretative presumption of specialist opinion. Cautiously but insistently, Melián’s project articulates an appeal to humanness and democracy in the face of the atrocities of National Socialism.”
                    </p>

                    <h4>Jury members</h4>

                    <ul class=" list-disc list-inside">
                        <li>Prof. Liz Bachhuber, Bauhaus University Weimar</li>
                        <li>Chris Dercon, Director, Haus der Kunst, Munich</li>
                        <li>Dr. Cornelia Gockel, Art critic, Munich</li>
                        <li>Prof. Dr. Raphael Gross, Director Leo Baeck Institute London<br>
                        Jewish Museum and Fritz Bauer Institute, Frankfurt</li>
                        <li>Prof. Dr. Kai-Uwe Hemken, Kunsthochschule Kassel</li>
                        <li>Dr. Angelika Nollert, Director, Neues Museum, Nuremberg</li>
                    </ul>

                </details>
            

                <details >
                    <summary>Michaela Melían</summary>

                    <p>Michaela Melián, an artist and musician, lives in Munich and Hamburg. She is a professor for time-based media at the Hochschule für bildende Künste (HfbK), Hamburg. She is a member of the band F.S.K. (Freiwillige Selbstkontrolle).</p>
                    <p>In 2008 Michaela Melián was the winner of the <a target="_blank" href="https://www.muenchen.de/rathaus/Stadtverwaltung/Kulturreferat/Bildende-Kunst/Kunst-im-oeffentlichen-Raum/Memory-Loops.html">competition</a> „Opfer des Nationalsozialismus – Neue Formen des Erinnerns und Gedenkens“ („Victims of National Socialism – New Forms of Remembering and Remembrance“), initiated by the City of Munich, with her concept <i>Memory Loops</i>.</p>
                    
                    <h4>Selection of exhibitions</h4>
                    <p> <i>Home Less Home</i>, Museum on the Seam, Jerusalem (2010); <i>See this Sound</i>, Lentos Museum Linz (2010); Ludlow 38, New York (2009); <i>The Dwelling</i>, ACCA Melbourne, Australia (2009); <i>Speicher</i>, Ulmer Museum (2008); <i>Speicher</i>, Cubitt Gallery, London (2008); <i>Recollecting</i>, MAK Vienna (2008); <i>Vertrautes Terrain</i>, ZKM Karlsruhe (2007); <i>TALK/SHOW</i>, k.m@tranzit dielne, Bratislava (2007); <i>Föhrenwald</i>, KW Kunstwerke Berlin (2006)</p>

                    <h4>Radio plays</h4>
                    <ul class=" list-disc list-inside">
                        <li><i>Speicher</i> <span class="tab"></span>(BR und Münchner Kammerspiele 2008)</li>
                        <li><i>Föhrenwald</i> <span class="tab"></span>(BR / kunstraum münchen 2005)</li>
                        <li><i>Konvent</i> <span class="tab"></span>(mit Thomas Meinecke und David Moufang, BR / ZKM / intermedium 2, 2002)</li>
                    </ul>
                    
                    <h4>Prizes</h4>
                    <ul class=" list-disc list-inside">
                        <li>2011 <span class="tab"></span>Radio Play of the Year presented by the Deutsche Akademie der Darstellenden Künste for <i>Memory Loops</i></li>
                        <li>2010 <span class="tab"></span>The City of Munich Art Prize</li>
                        <li>2009 <span class="tab"></span>Radio Play of the Year presented by the Deutsche Akademie der Darstellenden Künste for <i>Speicher</i></li>
                        <li>2006 <span class="tab"></span>Hörspielpreis der Kriegsblinden / award / Radio art prize for <i>Föhrenwald</i></li>
                        <li>2005 <span class="tab"></span>ARD Radio Play Prize and ARD-Online Award for <i>Föhrenwald</i></li>
                    </ul>
                    
                    <br>
                    
                    <p>“Her work and her use of both real and metaphorical space is spare yet encompassing, assured and quite moving.”<br>
                    Roberta Smith, <i>New York Times</i></p>

                    <p>“Michaela Melián’s artistic appropriation of history is reminiscent of Benjamin’s historico-philosophical concept concerning the dialectic image, in which past and present come together in one constellation, immediately shedding light on their relationship.”<br>
                    Petra Löffler, <i>Springerin contemporary art magazine</i>, Vienna</p>

                    <p>“German artist Michaela Melián has explored the politics of memory for many years now. Her works become blueprints for an approach to history in which the alacrity of deconstructive criticism is intimately tied to an appreciation of people whose life and work made a difference.”<br>
                    Jan Verwoert, <i>Frieze Magazine</i>, London</p>

                </details>

        </section>

        <br>

        <hr> <!-- ---------------------------------------------------------------------------- -->
        

        <section id="presse-und-archiv">
            
            <div class="md:flex" in:fly="{{delay: 0}}">
                <div class="w-1/2">
                    <h1>Press & Archive</h1>
                </div>
                <div class="w-1/2">
                </div>
            </div>

            <!-- <div class="md:flex" in:fly="{anim1}" >
                <details  >
                    <summary>Press contact</summary>
                    Press office at the Dept. of Arts and Culture, City of Munich:<br>
                    Jennifer Becker<br>
                    <a href="mailto:presse.kulturreferat@muenchen.de">presse.kulturreferat@muenchen.de</a><br> 
                    Tel: 089-233-26005<br>
                    Burgstraße 4, 80331 München
               </details>
            </div> -->

            <div class="md:flex" in:fly="{{delay: 200}}" >
                <details >
                    
                    <summary>Press Kit</summary>
                    <ul class="downloads list-inside">
                        You may download a complete package of all the files by following this link:<br>
                        <li>
                            <a href="{$download2Path}Memory_Loops_Press_Materials.zip">Memory Loops Presse Materialien</a> (zip, 49mb)
                        </li>
                    </ul>

                    <h4>Footage</h4>
                    <p>The use of images and motifs for coverage of the <i>Memory Loops</i> project is free of charge. All material used in publications must be clearly sourced and attention drawn to the copyright holder by adding the following:<br>
                    »Copyright: Michaela Melián & Surfacegrafik.de | Memory Loops 2010«</p>

                    <h4>Website</h4>
                    <ul class="downloads list-inside">
                        <li>
                            Motif 1: Start – 
                            <a href="{$download2Path}ml_browser_01_start_highres_4c_DE.zip">4-farb Print jpg (4mb) </a> / 
                            <a href="{$download2Path}ml_browser_01_start_lowres_rgb_DE.zip">RGB Screen jpg (770kb) </a>
                        </li>
                        <li>
                            Motif 2: Location Marker – 
                            <a href="{$download2Path}ml_browser_02_player_small_highres_4c_DE.zip">4-farb Print jpg (4mb)</a> / 
                            <a href="{$download2Path}ml_browser_02_player_small_lowres_rgb_DE.zip">RGB Screen jpg (760kb)</a>
                        </li>
                        <li>
                            Motif 3: Player – 
                            <a href="{$download2Path}ml_browser_03_player_reg_highres_4c_DE.zip">4-farb Print jpg (3,6mb)</a> / 
                            <a href="{$download2Path}ml_browser_03_player_reg_lowres_rgb_DE.zip">RGB Screen jpg (540kb)</a>
                        </li>
                        <li>
                            Motif 4: Closeup – 
                            <a href="{$download2Path}ml_browser_04_closeup_highres_4c_DE.zip">4-farb Print jpg (2,9mb)</a> / 
                            <a href="{$download2Path}ml_browser_04_closeup_lowres_rgb_DE.zip">RGB Screen jpg (300kb)</a>
                        </li>
                    </ul>

                    <h4>Mobile Website</h4>
                    <ul class="downloads list-inside">
                        <li>
                            Motif 1: Detail – 
                            <a href="{$download2Path}memoryloops_mobile_detail_highres_4c_DE.zip">4-farb Print jpg (2,7mb)</a> / 
                            <a href="{$download2Path}memoryloops_mobile_detail_lowres_rgb_DE.zip">RGB Screen jpg (235kb)</a>
                        </li>
                        <li>
                            Motif 1: List –  
                            <a href="{$download2Path}memoryloops_mobile_list_highres_4c_DE.zip">4-farb Print jpg (2,7mb)</a> / 
                            <a href="{$download2Path}memoryloops_mobile_list_lowres_rgb_DE.zip">RGB Screen jpg (221kb)</a>
                        </li>
                    </ul>

                    <h4>Campaignmotif</h4>
                    <ul class="downloads list-inside">
                        <li>
                            Campaignmotif German – 
                            <a href="{$download2Path}memoryloops_general_ad_highres_4c_DE.pdf" target="_blank" >Print 4C PDF (5mb)</a> /
                            <a href="{$download2Path}memoryloops_title_highres_4c_DE.zip">Print 4C JPG (9,4mb)</a> /
                            <a href="{$download2Path}memoryloops_title_lowres_rgb_DE.zip">Screen RGB JPG (470kb)</a>
                             
                        </li>
                        <li>
                            Campaignmotif English – 
                            <a href="{$download2Path}memoryloops_general_ad_highres_4c_EN.pdf" target="_blank" >Print 4C PDF (5mb)</a> / 
                            <a href="{$download2Path}memoryloops_title_highres_4c_EN.zip">Print 4C JPG (9,4mb)</a> / 
                            <a href="{$download2Path}memoryloops_title_lowres_rgb_EN.zip">Screen RGB JPG (470kb)</a>
                        </li>
                        <li>
                            Campaignmotif – 
                            <a href="{$download2Path}Hoerspiel_des_Jahres_2010.zip">Radio play of the year (german)</a>
                        </li>
                    </ul>

                    <h4>Texts</h4>
                    <ul class="downloads list-inside">
                        <li>
                            <a target="_blank" href="{$download2Path}Pressetext_MemoryLoops_short.pdf">Short press release  (pdf, german)</a>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Pressetext_MemoryLoops_long.pdf">Long press release  (pdf, german)</a>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Pressetext_MemoryLoops_kurz.pdf">Short press release  (pdf, english)</a>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Pressetext_MemoryLoops_lang.pdf">Long press release  (pdf, english)</a>
                        </li>
                    </ul>

                </details>
            </div>

            <div class="md:flex" in:fly="{{delay: 200}}" >

                <details >
                    <summary>Press Archive (Selected Reviews)</summary>
                    <ul class="downloads list-inside">
                        <li>
                            <a target="_blank" href="{$download2Path}Prism_2012-4.pdf">Prism – I will survive you all (Spring 2012, Volume 4)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}prix-europa.pdf">Prix Europa – Special Commendation – Online Category</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}FAZ-30.Maerz2011.pdf">Frankfurter Allgemeine Zeitung – Feuilleton – Hinter jedem Hörkreis wartet ein Schicksal (30. März 2011)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Hoerspiel_des_Jahres_2010.pdf">Hörspiel des Jahres 2010 – Deutsche Akademie der Darstellenden Künste (März 2011)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Ich_bin_argwoehnisch_gegenueber_jeder_staatstragenden_Metaphorik_Texte_zur_Kunst_4.1.2011.pdf">Texte zur Kunst – Ich bin argwöhnisch gegenüber jeder staatstragenden Metaphorik (04. Jan. 2011)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Shoa.de.pdf">Shoa.de – Der Sound der Erinnerung (Januar 2011)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Website_JuedischesMuseumMuenchen.pdf">Jüdisches Museum Muenchen – Website – Erinnerungsschleifen (Januar 2011)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}taz_27November2010.pdf">Taz – Die Tageszeitung – Tonspuren der Vergangenheit (27. Nov. 2010)<br></a>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Memory_Loops_in_Muenchen_Das_Unbehagen_am_geregelten_Gedenken_-_Kunst_-_Feuilleton_-_FAZ.NET.pdf">Frankfurter Allgemeine Zeitung – Feuilleton – Das Unbehagen am geregelten Gedenken (19. Nov. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}KunstForum_International_Bd_205_Nov_2010_Memory_Loops.pdf">KUNSTFORUM international (Bd 205, November 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}konkret_Heft_11_2010.pdf">Konkret – Sie müssen was gesehen haben (Heft 11 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Sueddeutsche_Feuilleton_Melian_12_10_2010.pdf">Süddeutsche Zeitung – Feuilleton – Erinnerungsschleifen (12. Okt. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}D_Welle_english_12Oktober2010.pdf">Deutsche Welle – DW-english – Digital memorial – (12. Okt. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}DeutscheWelle-World.de_5.10.2010.pdf">Deutsche Welle – DW-WORLD.DE – Virtuelles Denkmal (5. Okt. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}ZDF_Woche_im_Web_2Oktober2010.pdf">ZDF – Die Woche im Web (2. Okt. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Monopol_15.10.2010.pdf">Monopol – Interpol – Playlist der Vergangenheit (Oktober 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Hoerspiel_des_Monats_Oktober_2010.pdf">Hörspiel des Monats Oktober 2010 – Deutsche Akademie der Darstellenden Künste (November 2011)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Jud_Allgemeine_30September2010.pdf">Jüdische Allgemeine – Kultur – 300 Tonspuren (30. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}FAZ_27.Sept.2010.pdf">Frankfurter Allgemeine Zeitung – Wer Ohren hat, der höre (27. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}kulturSPIEGEL_27September2010.pdf">Kultur SPIEGEL – Kunst – Erinnerung im Loop (27. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Spiegel_online.pdf">Spiegel ONLINE – Kunst – Erinnerung im Loop (27. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Spex_24.9.2010.pdf">Spex – Schleifen der Erinnerung (24. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}merkur_24.09.2010.pdf">Münchner Merkur – Feuilleton – Die hörbare Alltäglichkeit des Grauens (24. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Sueddeutsche_23_09_2010.pdf">Süddeutsche Zeitung – Das ganz normale Entsetzen (23. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Frankfurter_Rundschau-_Nutzer-Oberflaeche_fuer_die_Geschichte_22.9.2010.pdf">Frankfurter Rundschau – Feuilleton – Nutzer-Oberfläche für die Geschichte (22. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Radar_SuperPaper_Sept2010.pdf">SuperPaper – RADAR-Interview (September 2010)</a>
                        </li>
                    </ul>
                </details>

            </div>

            <div class="" in:fly="{{delay: 200}}" >

                <details >
                    <summary>Photo Archive</summary>

                    <div class="flex" in:fly="{{delay: 200}}" >
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Dercon_Melian_Kuenstlergespraech.jpg">
                            <img width="150px" src="{$download2Path}Dercon_Melian_Kuenstlergespraech.jpg" alt="Dercon_Melian_Kuenstlergespraech" />
                        </a>
                        <a target="_blank" href="{$download2Path}Dercon_Melian_Kuenstlergespraech-2.jpg">
                            <img width="150px" src="{$download2Path}Dercon_Melian_Kuenstlergespraech-2.jpg" alt="Dercon_Melian_Kuenstlergespraech-2" />
                        </a>
                    </div>
                    <p><i>Memory Loops</i> – Michaela Melián in conversation with Chris Dercon, Director, Haus der Kunst, Munich, 26 September 2010, Rathausgalerie Kunsthalle, Munich</p>    

                    <div class="flex" in:fly="{{delay: 200}}" >
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Assmann_Hirsch_Welzer_Panel-2.jpg">
                            <img width="150px" src="{$download2Path}Assmann_Hirsch_Welzer_Panel-2.jpg" alt="Assmann_Hirsch_Welzer_Panel-2"/>
                        </a>
                        <a target="_blank" href="{$download2Path}Assmann_Hirsch_Welzer_Panel-1.jpg">
                            <img width="150px" src="{$download2Path}Assmann_Hirsch_Welzer_Panel-1.jpg" alt="Assmann_Hirsch_Welzer_Panel-1" />
                        </a>
                    </div>
                    <p><i>Zeitgenössische künstlerische Formen der Erinnerung im öffentlichen Raum</i>, Podium discussion with Aleida Assmann, Nikolaus Hirsch und Harald Welzer, 30 September 2010, Rathausgalerie Kunsthalle, Munich</p>
                        
                    <div class="flex" in:fly="{{delay: 200}}" >
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Billboard_Marienplatz.jpg">
                            <img width="150px" src="{$download2Path}Billboard_Marienplatz.jpg" alt="Billboard_Marienplatz">
                        </a> <br> 
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Billboard_Odeonsplatz.jpg">
                            <img width="150px" src="{$download2Path}Billboard_Odeonsplatz.jpg" alt="Billboard_Odeonsplatz">
                        </a> <br> 
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Billboard_Dachauer_Str.jpg">
                            <img width="150px" src="{$download2Path}Billboard_Dachauer_Str.jpg" alt="Billboard_Dachauer_Str">
                        </a><br>
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Lifasssaeule_Lazarettstrasse-1.jpg">
                            <img width="150px" src="{$download2Path}Lifasssaeule_Lazarettstrasse-1.jpg" alt="Lifasssaeule_Lazarettstrasse-1">
                        </a><br>
                        <a target="_blank" href="{$download2Path}Litfasssaeule_Sonnenstrasse-2.jpg">
                            <img width="150px" src="{$download2Path}Litfasssaeule_Sonnenstrasse-2.jpg" alt="Litfasssaeule_Sonnenstrasse-2">  
                        </a><br>
                    </div>
                    <p><i>Memory Loops</i> advertising</p>    
                        
                    <div class="flex" in:fly="{{delay: 200}}" >
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Memoryloops_Schild_Hohenzollernstrasse.jpg">
                        <img width="150px" src="{$download2Path}Memoryloops_Schild_Hohenzollernstrasse.jpg" alt="Memoryloops_Schild_Hohenzollernstrasse">
                        </a><br>
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Memoryloops_Schild_Max-Joseph-Platz.jpg">
                        <img width="150px" src="{$download2Path}Memoryloops_Schild_Max-Joseph-Platz.jpg" alt="Memoryloops_Schild_Max-Joseph-Platz">  
                        </a><br>
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Memoryloops_Schild_Odeonsplatz.jpg">
                        <img width="150px" src="{$download2Path}Memoryloops_Schild_Odeonsplatz.jpg" alt="Memoryloops_Schild_Odeonsplatz">
                        </a><br>
                        <a target="_blank" href="{$download2Path}Memoryloops_Schild_Justizpalast.jpg">
                        <img width="150px" src="{$download2Path}Memoryloops_Schild_Justizpalast.jpg" alt="Memoryloops_Schild_Justizpalast">
                        </a><br>
                    </div>
                    <p>Signs with telephone numbers around Munich</p>
                
                </details>
            </div>

            <div class="" in:fly="{{delay: 200}}" >

                <details >
                    <summary>Opening and Events</summary>

                    <h4>Opening</h4>
                    <p>
                        Thursday, 23 September, 2010, 6 p.m.<br>
                        Rathausgalerie Kunsthalle (City Hall Art Gallery), Marienplatz 8, Munich<br>
                        <br>
                        Speakers include<br>
                        Michael Leonhart, Munich City Council, representing the Lord Mayor<br>
                        Dr. Hans-Georg Küppers, Head of Municipal Dept. of Arts and Culture, Munich<br>
                        Dr. Johannes Grotzky, Director, Bavarian Broadcasting Corporation<br>
                        <br>
                        The artwork will be presented at the Rathausgalerie Kunsthalle with a number of audio points until 3 October, 2010.<br>
                        Opening times: Tues. – Sun.: 11 a.m.–7 p.m.<br>
                    </p>

                    <h4>Events</h4>
                    <p>
                        Sunday, 26 September, 2010, 11 a.m.<br>
                        Rathausgalerie Kunsthalle (City Hall Art Gallery), Marienplatz 8, Munich<br>
                        <span class="text-white"> <i>Memory Loops</i> – Michaela Melián in conversation with Chris Dercon, Director, Haus der Kunst, Munich</span><br>
                    </p>

                    <p>
                        Thursday, 30 September, 2010, 7 p.m.<br>
                        Rathausgalerie Kunsthalle (City Hall Art Gallery), Marienplatz 8, Munich<br>
                        <span class="text-white">Podium discussion <i>Gedenk.Kunst (Memory.Art) – contemporary artistic possibilities for memorials in public spaces</i></span><br>
                        Welcoming speech by Dr. Hans-Georg Küppers, Head of Municipal Dept. of Arts and Culture, Munich<br>
                        Guests<br>
                        Prof. Dr. Aleida Assmann, University of Konstanz – Literature Studies/English<br>
                        Prof. Nikolaus Hirsch, future Head of the Städelschule, Frankfurt am Main<br>
                        Prof. Dr. Harald Welzer, Head of research into Interdisciplinary Memory Research,<br> Institute for Advanced Study in the Humanities, Essen<br>
                        Moderation<br>
                        Julian Doepp, Bavarian Broadcasting Corporation / Radio Play and Media Art Dept. <br>
                    </p>

                    <h4><i>Memory Loops</i> on Bayern 2</h4>
                    <p>
                        24 September 2010, 8.30 p.m.<br>
                        New ways to remember – the <i>Memory Loops</i> art project (in German)<br>
                        Michaela Melián in conversation with Ania Mauruschat on in hör!spiel!art.mix, Bayern 2<br>
                    </p>
                    <p>
                        Broadcasting of the five, one-hour <i>Memory Loops</i><br>
                        Sundays, from 26 September to 24 October, 2010 at 3 p.m.<br>
                        Mondays, from 27 September to 25 October, 2010 at 8.30 p.m.<br>
                    </p>
                    <p>
                        Excerpts of the podium discussion to be broadcast on<br>
                        1 October, 2010, 8.30 p.m. in hör!spiel!art.mix, Bayern 2<br>
                    </p>

                </details>
            </div>

        </section>


        <br>
        <br>
        <br>
        <br>
        <br>
        <br>

    </div>
</div>
