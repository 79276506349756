<!-- -------------------------------------------------------------------------------------------- -->

<script>
    import {fade} from 'svelte/transition'
    import {fly} from 'svelte/transition'
    import { quadInOut } from 'svelte/easing';
</script>


<!-- -------------------------------------------------------------------------------------------- -->



<div class="info-content" in:fly="{{duration: 500, opacity: 0.0, easing: quadInOut}}">
    <div class="container" in:fly="{{delay: 100, duration: 500, y: 50, opacity: 0.0, easing: quadInOut}}">



        <section id="impressum">

            <h1>Imprint</h1>

            <h4 class="text-white">
                Michaela Melián<br>
                Memory Loops<br>
                175 Audio Tracks on Sites of NS Terror in Munich 1933–1945
            </h4>

            <br>

            <p>A project by the Municipal Department of Arts and
            Culture, Munich / ‘Freie Kunst im öffentlichen Raum’.
            <br>In cooperation with the Bavarian Broadcasting
            Corporation / Radio Play and Media Art Dept.</p>
            
            
            <br>
            
            
            <h4>Concept, composition and execution</h4>
            <p>Michaela Melián</p>
            
            <h4>Art Direction</h4>
            <p>Markus Weisbeck / <a target="_blank" href="http://surfacegrafik.de">Surface Gesellschaft für Gestaltung</a>, Frankfurt am Main / Berlin</p>

            <h4>Website / Digital Media</h4>
            <p>Stefan Ammon / <a target="_blank" href="https://neue-raeumlichkeit.net">Neue Räumlichkeit</a>, Frankfurt am Main</p>
            
            <h4>Studioproduction</h4>
            <p>Mario Thaler und Michaela Melián</p>

            <h4>Voices</h4>
            <p>Peter&nbsp;Brombacher, Ferdinand&nbsp;von&nbsp;Canstein, Anna&nbsp;Clarin, Caroline&nbsp;Ebner, Florian&nbsp;Fischer, Julia&nbsp;Franz, Nicola&nbsp;Hecht, Gabriel&nbsp;Ascanio&nbsp;Hecker, David&nbsp;Herber, Johannes&nbsp;Herrschmann, Hans&nbsp;Kremer, Julia&nbsp;Loibl, Laura&nbsp;Maire, Juno&nbsp;Meinecke, Thomas&nbsp;Meinecke, Stefan&nbsp;Merki, Wolfgang&nbsp;Pregler, Steven&nbsp;Scharf, Joana&nbsp;Verbeek&nbsp;von&nbsp;Loewis</p>

            <h4>Music realised by</h4>
            <p>Michaela Melián und Carl Oesterhelt</p>
            
            <h4>Voice Recording</h4>
            <p>Susanne Herzig, Hans Scheck</p>
            
            <h4>Production and project assistant</h4>
            <p>Kirsten Böttcher</p>
            
            <h4>Research</h4>
            <p>Kirsten&nbsp;Böttcher, Dana&nbsp;Brüller, Anja&nbsp;Deutsch, Elisabeth&nbsp;Dietrich, Jennifer&nbsp;Güzel, Barbara&nbsp;Hutzelmann, Michaela&nbsp;Melián, Tina&nbsp;Rank, Katharina&nbsp;Ruhland, Jakob&nbsp;Schäfer, Severin&nbsp;Viktor</p>
            
            <h4>Translations from the German</h4>
            <p>Christopher Jenkin-Jones, Christopher Wynne</p>

            <h4>Englisch-Coach</h4>
            <p>Christopher Jenkin-Jones</p>
            
            <h4>Casting</h4>
            <p>Andrea Fenzl</p>
            
            <h4>Model maker</h4>
            <p>Michael Juraschek</p>

            
            
            <hr>



            <h3>Acknowledgements</h3>
            
            <p>Thanks to following for their assistance, for kindly making material available and for support provided in many different ways during the making of <i>Memory Loops</i>:</p>

            <p>Karin&nbsp;Achtelstätter, Abrasha&nbsp;Arluk(†), Benjamin&nbsp;Baum, Angelika&nbsp;Baumann, Martin&nbsp;Baumeister, Avital&nbsp;Ben-Chorin, Barbara und Konrad&nbsp;O.&nbsp;Bernheimer, Hans&nbsp;Besold, Lioba&nbsp;Betten, Sabine&nbsp;Brantl, Michael&nbsp;Brenner, Heike&nbsp;Bretschneider, Ulrich&nbsp;Chaussy, Claudius&nbsp;Coenen, Marion&nbsp;Detjen, Veronika&nbsp;Diem, Ludwig&nbsp;Eiber, Christine&nbsp;Emrich, Lisbeth&nbsp;Exner, Michael&nbsp;Farin, Florian&nbsp;Feineis, Jutta&nbsp;Fleckenstein, Gabriele&nbsp;Förg, Bea&nbsp;Green, Ernst&nbsp;Grube, Werner&nbsp;Grube, Egon&nbsp;Günther, Bettina&nbsp;Hasselbring, Dirk&nbsp;Heißerer, Franz&nbsp;Herre, Andreas&nbsp;Heusler, Hugo&nbsp;Höllenreiner, Gerrit&nbsp;Hohendorf, Corinna&nbsp;Horban, Gitta&nbsp;Jäger, Gerhard&nbsp;Jochem, Wolfram&nbsp;P.&nbsp;Kastner, Albert&nbsp;Knoll, Armin&nbsp;Köhler, Resi&nbsp;Kohlhofer, Andreas&nbsp;Koll, Sybille&nbsp;Krafft, Farina&nbsp;Krause, Christoph&nbsp;Lindenmeyer, Irmela&nbsp;von&nbsp;der&nbsp;Lühe, Thies&nbsp;Marsen, Ilse&nbsp;Macek, Max&nbsp;Mannheimer, Leo&nbsp;Melian, Markus&nbsp;Metz, Janina&nbsp;Moser, Winfried&nbsp;Nerdinger, Manuel&nbsp;Neumeyer (†), Diana&nbsp;Oesterle, Charlotte&nbsp;Ottow-von&nbsp;Bornhard, Johann-Christoph&nbsp;Ottow, Inez&nbsp;Rattan, Josef&nbsp;Anton&nbsp;Riedl, Sabine&nbsp;Rittner, Martin&nbsp;Ruch, Nicole&nbsp;Sachmann, Sabine&nbsp;Schalm, Robert&nbsp;Schopflocher, Brigitte&nbsp;Schmidt, Martin&nbsp;Schuster, Georg&nbsp;Seeßlen, Katrin&nbsp;Seybold, Uri&nbsp;Siegel, Peter&nbsp;Sinclair (†), Michael&nbsp;Skasa, Rainer&nbsp;Traub, Ulrich&nbsp;Trebbin, Jan&nbsp;Trüller, Elisabeth&nbsp;Tworek, Rainer&nbsp;Volk, Reinhard&nbsp;Weber, Manfred&nbsp;Wegner, Klaus&nbsp;Weisenbach, Christa und Peter&nbsp;Willmitzer</p>

            <p>
                Archiv der Münchner Arbeiterbewegung e.V.<br>
                Archiv Katrin Seybold Film, München<br>
                Bayerischer Rundfunk, München, Historisches Archiv<br>
                Bayerischer Rundfunk, München, Hörfunkarchive / Dokumentation Wort<br>
                Bayerisches Hauptstaatsarchiv, München<br>
                Belleville Verlag, München<br>
                Bundesarchiv Berlin<br>
                Bundeszentrale für politische Bildung, Bonn<br>
                Compact Memory, Internetarchiv für jüdische Periodika, Aachen: <a href="http://www.compactmemory.de" target="_blank" rel="noopener">www.compactmemory.de</a><br>
                Die Münchner ISK-Gruppe: <a href="http://www.compactmemory.de" target="_blank" rel="noopener">www.isk-muenchen.de</a><br>
                Gedenkstätte Deutscher Widerstand, Berlin: <a href="http://www.georg-elser.de" target="_blank" rel="noopener">www.georg-elser.de</a><br>
                Georg-Elser-Arbeitskreis Heidenheim: <a href="http://www.georg-elser-arbeitskreis.de" target="_blank" rel="noopener">www.georg-elser-arbeitskreis.de</a><br>
                Haus der Bayerischen Geschichte, Augsburg<br>
                Haus der Kunst, München, Historisches Archiv<br>
                Historisches Seminar der Ludwig-Maximilians-Universität München, Jüdische Geschichte und Abteilung Neueste Geschichte und Zeitgeschichte<br>
                Institut für Geschichte und Ethik der Medizin der Technischen Universität München<br>
                Institut für Zeitgeschichte (IfZ), München<br>
                Jüdisches Museum München<br>
                Koordinierungsstelle für gleichgeschlechtliche Lebensweisen, München<br>
                KZ-Gedenkstätte Dachau, Archiv<br>
                Literaturarchiv Monacensia, München<br>
                Metropol Verlag, Berlin<br>
                MünchenVerlag, München<br>
                NS-Dokumentationszentrum, München<br>
                Puppentheater-Museum, Münchner Stadtmuseum<br>
                Rijo Research, Nürnberg: <a href="http://www.rijo-research.de" target="_blank" rel="noopener">www.rijo-research.de</a><br>
                RomNews Society, Hamburg: <a href="http://www.romnews.com" target="_blank" rel="noopener">www.romnews.com</a>, <a href="http://www.romahistory.com" target="_blank" rel="noopener">www.romahistory.com</a><br>
                Schalom Ben-Chorin, Jugend an der Isar, München 1974<br>
                Staatsarchiv München<br>
                Stadtarchiv München<br>
                Stadtmuseum München<br>
                Städtische Galerie im Lenbachhaus München<br>
                Suhrkamp Verlag, Berlin<br>
                Testimon Verlag, Nürnberg<br>
                Widerstand, Protest, Verweigerung gegen das NS-Regime in München: <a href="https://web.archive.org/web/20010123184600/http://www.widerstand.musin.de/w3-11.html" target="_blank" rel="noopener">www.widerstand.musin.de</a><br>
            </p>

            <hr>


            <h3>Contact</h3>

            <h4>Department of Arts and Culture, City of Munich</h4>
            <p>
                Dept. 1 / Public Art team<br>
                Burgstraße 4<br>
                D-80331 Munich<br>
                Tel: +49-(0)89-233-26087<br>
                Email: <a href="mailto:kultur.foerderung@muenchen.de">kultur.foerderung@muenchen.de</a>
                <!-- Email: <a href="mailto:kerstin.moeller@muenchen.de">kerstin.moeller@muenchen.de</a><br> -->
            </p>

            <h4>Bavarian Broadcasting Corporation / Radio Play, Documentary and Media Art Dept.</h4>
            <p>                
                <!-- Katarina Agathos<br> -->
                Rundfunkplatz 1<br>
                D-80335 Munich<br>
                Tel: +49-(0)89-5900-2252<br>
                Email: <a href="mailto:hoerspiel@brnet.de">hoerspiel@brnet.de</a><br>
            </p>


            <!--
            <p>
                Bayerischer Rundfunk<br>
                Redaktion Hörspiel / Dokumentation / MedienkunstRundfunkplatz 1<br>
                80335 München<br>
                Tel: +49-(0)89-5900-35857<br>
                Email: <a href="mailto:hoerspiel@brnet.de">hoerspiel@brnet.de</a><br>  
            </p>
            <p>      
                Kulturreferat der Landeshauptstadt München
                Abteilung 1<br>
                Burgstraße 4<br>
                80331 München<br>
                Tel: +49-(0)89-233-26087<br>
            </p> 
            -->
            
            <hr>

            <h3>Information appropriate by §5 TMG:</h3>
            <p>
                <br><img src="../assets/images/munich-wb-en.png" alt="München Logo"><br>

                City of Munich<br>
                Represented by the Director of the Department of Arts and Culture Anton Biebl<br>
                <br>
                Department of Arts and Culture<br>
                Department 1 <br> 
                Burgstraße 4<br>
                D-80331 Munich<br>
            </p>
            <p>
                Tel: +49-(0)89–233–26087<br>
                Fax: +49-(0)89–233–21269<br>
                Email: <a href="mailto:kultur.foerderung@muenchen.de">kultur.foerderung@muenchen.de</a>
            </p>
            
            
            <h4>Value-added tax ID number pursuant to §27a Value-added Tax Act:</h4>
            <p>DE129524000 Statutory corporation under public law<br></p>

            <h4>Statutory corporation under public law:</h4>    
            <p>Government of Upper Bavaria <br></p>

        
        </section>



        <br>
        <br>
        <br>
        <br>
        <br>
        <br>

    </div>
</div>
