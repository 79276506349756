//
import Home from "./Routes/Home.svelte";
import About from "./Routes/About.svelte";
import Help from "./Routes/Help.svelte";
import Radioplay from "./Routes/Radioplay.svelte";
import Privacy from "./Routes/Privacy.svelte";
import Imprint from "./Routes/Imprint.svelte";

import Start from "./Routes/Start.svelte";
import Ueber from "./Routes/Ueber.svelte";
import Hoerspiel from "./Routes/Hoerspiel.svelte";
import Hilfe from "./Routes/Hilfe.svelte";
import Datenschutz from "./Routes/Datenschutz.svelte";
import Impressum from "./Routes/Impressum.svelte";

let routes;

routes = {
  "/": Home,

  "/home": Home,
  "/about": About,
  "/radioplay": Radioplay,
  "/help": Help,
  "/privacy": Privacy,
  "/imprint": Imprint,

  "/home": Start,
  "/ueber": Ueber,
  "/hoerspiel": Hoerspiel,
  "/hilfe": Hilfe,
  "/datenschutz": Datenschutz,
  "/impressum": Impressum,
};

export default routes;
