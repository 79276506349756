<!-- -------------------------------------------------------------------------------- -->

<script context="module">

  export const TheLoopList = {};
	export let me = {}

</script>

<!-- -------------------------------------------------------------------------------- -->

<script>
	export let active = false
  import { storedLoops } from "../Store/store.js"
	import { setContext } from 'svelte';
  import { writable } from 'svelte/store';

  import LoopListItem from "./LoopListItem.svelte";
		
	let ids = 0;
	export const activeItem = writable(null);
	
	setContext( TheLoopList, {
		registerItem: () => ids += 1,
		activeItem
	});
	$: console.log("ID:", $activeItem)

	export function deactivateAll() {
		console.log("Holla Di Hoh")
	}

</script>

<!-- -------------------------------------------------------------------------------- -->

{#each $storedLoops as loop}
  <LoopListItem item={loop} context={TheLoopList} bind:this={me}/>
{/each}

