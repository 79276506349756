<!-- ---------------------------------------------------- -->

<script>
    import { currentLoop, focusedLoop, currentLoopIsPlaying } from '../Store/store.js';
    export let currentID = '';
    export let type = '';
</script>

<!-- ---------------------------------------------------- -->

<button id="loopIcon" class="button"
    class:default="{true}" 
    class:focused="{$focusedLoop === currentID}"
    class:active="{$currentLoop === currentID && $currentLoopIsPlaying}"
    class:paused="{$currentLoop === currentID && !$currentLoopIsPlaying}"
    class:big="{type === 'player'}"
    class:small="{type === 'list'}"
></button>
<!-- on:mouseover="{() => console.log("currentID", currentID, "currentLoop", $currentLoop, "type:", type)}" -->

<!-- ---------------------------------------------------- -->

<style>
    #loopIcon {
        display: flex;
        align-items: center;
        width: 32px;
        height: 32px;
        /* background: url('./assets/images/oval-index.png') no-repeat; */
        background-position: center;
        align-self: center;
        margin: 2px !important;
    }

    .small {
        background-position: center !important;
        background-size: 75% !important;
    }
    .big {
        background-position: center !important;
        background-size: 100% !important;
    }
    .default {
        background: url('./assets/images/oval-index.png') no-repeat;
    }
    .focused {
        background: url('./assets/images/oval-active-vector-fill.svg') no-repeat;
    }
    .active {
        animation: rotation 2s infinite linear;
        animation-play-state: running;
        background: url('./assets/images/oval-active-vector-fill.svg') no-repeat;
    }
    .paused {
        animation: rotation 2s infinite linear;
        animation-play-state: paused;
        background: url('./assets/images/oval-active-vector-fill.svg') no-repeat;
    }
    @keyframes rotation {
        from { transform: rotate(0deg); }
        to { transform: rotate(359deg); }
    }

</style>

