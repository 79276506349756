<!-- -------------------------------------------------------------------------------------------- -->

<script>
    import {fade} from 'svelte/transition'
    import {fly} from 'svelte/transition'
    import { quadInOut } from 'svelte/easing';
    import { download2Path } from '../Store/store.js';
</script>



<!-- -------------------------------------------------------------------------------------------- -->



<div class="info-content" in:fly="{{duration: 500, opacity: 0.0, easing: quadInOut}}">
    <div class="container" in:fly="{{delay: 100, duration: 500, y: 50, opacity: 0.0, easing: quadInOut}}">



        <section id="hoerspiel">

            <div class="md:flex" in:fly="{{delay: 0}}">
                <div class="md:w-1/2">
                    <h1>Radio Play</h1>
                </div>
                <div class="md:w-1/2">
                </div>
            </div>

            <div class="md:flex" in:fly="{{delay: 0}}">
                <div class="md:w-1/2 md:pr-6">
                    <p>Five of the 300 German audio tracks are one-hour <i>Memory Loops</i> that cover the whole city area with a focus on different subjects. A <i>Loop</i> in English is also available. These voice-collages were broadcast as a radio series on Bayern 2 in September and October 2010, and can also be downloaded as podcasts.</p>
                    
                    <!-- <p>MP3 players with all one-hour Memory Loops audio tracks are available for hire free of charge in a number of museums and institutions in Munich:  -->
                    <!-- 
                    <a target="_blank" href="http://www.stadtmuseum-online.de/">Münchner Stadtmuseum</a>, 
                    <a target="_blank" href="http://www.juedisches-museum-muenchen.de/"> Jüdisches Museum München,</a> 
                    <a target="_blank" href="http://www.villastuck.de/">Museum Villa Stuck,</a> 
                    <a target="_blank" href="http://www.villastuck.de/">Lenbachhaus museum shop in the Ruffini building,</a>  
                    <a target="_blank" href="http://www.hausderkunst.de/">Haus der Kunst.</a>
                     -->
                    
                    <ul class="link-external list-inside">
                        <h3>
                            <li>
                                <a target="_blank" href="https://www.br.de/radio/bayern2/sendungen/hoerspiel-und-medienkunst/hoerspiel-pool/memory-loops102.html">To the podcasts of Bayern 2</a><br>
                            </li>
                        </h3>
                    </ul>

                    <ul class="downloads list-inside">
                        <li>
                            <a target="_blank" href="{$download2Path}Memory_Loops_-_1_-_English_Radio_Play.mp3">Loop in English</a>
                        </li>
                    </ul>
                    <ul class="downloads list-inside">
                        <li>
                            <a target="_blank" href="{$download2Path}ML_Druckversion_Hoerspieldownload_Website.pdf">Info Radio Play</a>
                        </li>
                    </ul>
                </div>
                <!--
                <div class="md:w-1/2">
                    <ul class="downloads list-disc list-inside">
                        <li>
                            <a href="https://ml.nr-io.de/wp-content/uploads/assets/downloads/Memory_Loops_-_1_-_Herzog-Max-Strasse_Herzog-Rudolf-Strasse_Antonienstrasse_Knorrstrasse.zip">Loop 1</a> 
                            <p>Herzog-Max-Straße Herzog-Rudolf-Straße Antonienstraße Knorrstraße</p>
                        </li>
                        <li>
                            <a href="https://ml.nr-io.de/wp-content/uploads/assets/downloads/Memory_Loops_-_2_-_Prinzregentenplatz_Lindwurmstrasse_Hauptbahnhof_Odeonsplatz.zip">Loop 2</a> 
                            <p>Prinzregentenplatz Lindwurmstraße Hauptbahnhof Odeonsplatz</p>
                        </li>
                        <li>
                            <a href="https://ml.nr-io.de/wp-content/uploads/assets/downloads/Memory_Loops_-_3_-_Ettstrasse_Maistrasse_Haar_Hackerbruecke.zip">Loop 3</a> 
                            <p>Ettstraße Maistraße Haar Hackerbrücke</p></li>
                        <li>
                            <a href="https://ml.nr-io.de/wp-content/uploads/assets/downloads/Memory_Loops_-_4_-_Gaertnerplatz_Marsstrasse_Promenadeplatz_Prinzregentenstrasse.zip">Loop 4</a> 
                            <p>Gärtnerplatz Marsstraße Promenadeplatz Prinzregentenstraße</p>
                        </li>
                        <li>
                            <a href="https://ml.nr-io.de/wp-content/uploads/assets/downloads/Memory_Loops_-_5_-_Pestalozzistrasse_Ettstrasse_Brienner_Strasse_Mariannenbruecke.zip">Loop 5</a> 
                            <p>Pestalozzistraße Ettstraße Brienner Straße Mariannenbrücke</p>
                        </li>
                        <li>
                            <a href="https://ml.nr-io.de/wp-content/uploads/assets/downloads/ML_Druckversion_Hoerspieldownload_Website.pdf">Info Hörspiele</a>
                            <p>Druckversion Hörspiel</p>
                        </li>
                    </ul>
                </div>
                -->
            </div>

        </section>

        <br>
        <br>
        <br>
        <br>
        <br>
        <br>

    </div>
</div>
