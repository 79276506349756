<!-- -------------------------------------------------------------------------------- -->

<svelte:head>
	<script src="./assets/krpano/js/pano.js" on:load={panoLoaded}></script>
</svelte:head>

<!-- -------------------------------------------------------------------------------- -->

<div id="pano" class="fixed w-full h-full" transition:fade ></div>

<!-- -------------------------------------------------------------------------------- -->

<script >
	import { onMount, getContext } from 'svelte';
	import { fade } from 'svelte/transition';
	import {
		mapPath,
		storedLoops, 
		krpano, 
	} from '../Store/store.js';	

	export let ref = "";
	let _app = getContext( 'app' );
	let panoReady = false;
	let mounted = false;


	onMount(() => {
		// console.error("|-- Krpano mounted", _app, mapPath)
		mounted = true;
		// return () => { console.warn("Unmounting Krpano") };
	});


	function panoLoaded() {
		panoReady = true;
		if (mounted) {
			embedpano({
				// swf: "./assets/krpano/pano.swf",
				xml: "./assets/krpano/"+ref,
				target: "pano",
				html5: "only",
				mobilescale: 1.0,
				passQueryParameters: true,
				onready: init,
				consolelog: true,
				initvars: {
					lang: 'de',
					mapPath: $mapPath,
				},
				// id: "blipp",
				bgcolor: "#181818"
			});
		}
	}
				// basepath: "../../",

	function init( krpano_interface ) {
		// TODO
		// var krp = krpano_interface.get("global");
		$krpano = krpano_interface.get("global");

		// $krpano.actions.resize4(function(){
    //   console.log("hi")
    // });
		// $krpano._krpanolicense = {}
		// $krpano.control.dragfriction = 0.1;
					
		if (_app.trace) console.log("|---- $krpano stagewidth:", $krpano.get("stagewidth"), $krpano.stageheight)
		setTimeout(()=>(handler()),1500)

		function handler() {
			app.states.set('LOADED')
			_app.createHotspots(400)
		}
	}

</script>

