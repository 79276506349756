// -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// -------------------------------------------------------------------------

// console.warn( "|-- ||||||||||||||||||  store.js | initialized  ||||||||||||||||||" );

import { writable } from "svelte/store";
import { readable } from "svelte/store";

// import { deleteHotspots2 } from "../Krpano/Krpano.svelte";

// -------------------------------------------------------------------------
// -------- Loading Memory Loops JSON --------------------------------------
// -------------------------------------------------------------------------

import data from "../Assets/json/memoryloops.json";

let sortKeys = "";
export const lookupTable = [];

let groups = Object.values(data.groups);
let memoryloops_raw = Object.values(data.memoryloops);
let memoryloops = [];
for (let item of memoryloops_raw) {
  item.loop.lang = item.loop.lang.toLowerCase();
  memoryloops.push(item.loop);
  if (item.loop.lang === "de") lookupTable.push(item.loop.file);
}
export let memoryloops_de = memoryloops.filter((loop) =>
  loop.lang.includes("de")
);
export let memoryloops_en = memoryloops.filter((loop) =>
  loop.lang.includes("en")
);

// console.log("--------- memoryloops: ", memoryloops);
// console.log("--------- memoryloops: ", memoryloops_de);
// console.log("--------- Groups DE: ", data.groups["DE"]);
// console.log("--------- Groups EN: ", data.groups["EN"]);
// console.log(
//   "--------- M M M M memoryloops: ",
//   memoryloops.filter(loop => loop.file == 20),
//   "--------- M M M M memoryloops: "
//   // memoryloops.filter(loop => loop.file.includes(20))
// );

let groups_de = data.groups["DE"];
let groups_en = data.groups["EN"];

// -------------------------------------------------------------------------

// async function loadJSON(url) {
//   const res = await fetch(url);
//   return await res.json();
// }
// loadJSON("./assets/krpano/memoryloops.json").then(data => {
//   console.error("|||||||||||||||||||  store.js | memoryloops.json loaded");
//   // let memoryloops = data["memoryloops"];
//   for (let item of data["memoryloops"]) {
//     memoryloops.push(item.loop);
//   }
//   memoryloops_de = _.filter(memoryloops, ["lang", "en"]);
//   memoryloops_en = _.filter(memoryloops, ["lang", "en"]);
//   storedLoops.set(memoryloops_de);
//   console.log("|-------> storedLoops", storedLoops);
//   // console.log("|-------> Memory Loops loaded");
// });

// -------------------------------------------------------------------------

// export function parseLoop(loop) {
//   console.log("XXXXXXXX---XXXXXXX------XXXXXX:", loop);
//   currLoop.id = getLoopID(loop);
//   currLoop.label = getLoopAddress(loop);
//   console.log("OOOOOOO---OOOOOOO------OOOOOO:", currLoop.id, currLoop.label);
// }

function zeroPad(num, places) {
  return num.toString().padStart(places, "0");
}
function getLoopAddress(loop) {
  return loop.text.split(",")[0];
}
function getLoopID(loop) {
  return (
    zeroPad(loop.id, 3).toString() +
    (loop.pin.nr == "" ? " " : "–") +
    loop.pin.nr
  ).trim();
}

// -------------------------------------------------------------------------

// export let player = writable({
//   isTracking: false,
//   currentLoop: "",
//   test: { eins: 1, zwei: "zwei" }
// });
// player.subscribe(value => {
//   console.log("|-- Subscribe > player:", value.isTracking, value.test.eins);
// });
// player.update(value => {
//   console.log("|-- Update > player:", value.isTracking, value.test.eins);
// });

function createPlayer() {
  const { subscribe, set, update } = writable(false);
  return {
    subscribe,
    increment: () => update((n) => n + 1),
    toggle: () => update((n) => !n),
    setFalse: () => set(false),
    setTrue: () => set(true),
  };
}
export const Player = createPlayer();

// function createObject() {
//   const { subscribe, set, get, update } = writable({ eins: "EINS", zwei: 2 });
//   return {
//     subscribe,
//     blubb: () => update(n => blubb(n.eins)),
//     toggle: () => update(n => !n),
//     setFalse: () => set(false),
//     setTrue: () => set(true),
//     getEins: () => update(n => n)
//   };
// }
// export const Objekt = createObject();

// function blubb(n) {
//   // return n;
//   console.log("Blubber: ", n);
// }

// -------------------------------------------------------------------------

export const loopFileType = writable(
  navigator.product === "Gecko" ? ".ogg" : ".mp3"
);

export const basePath = "";
export const dataFromExtern = false;
if (dataFromExtern) {
  basePath = "http://www.memoryloops.org";
}
// export const Preview = writable({1,2,3});
export let mapPath = writable("../../map");
export let audioPath = writable("/audio/lq/");
export let thumbPath = writable("/assets/images/thumbs/");
export let downloadPath = writable(basePath + "/downloads/loops/");
export let download2Path = writable(basePath + "/downloads/");

// if (dataFromExtern) {
//   audioPath = "https://ml.nr-io.de/wp-content/uploads/maps";
//   mapPath = writable("https://ml.nr-io.de/wp-content/uploads/maps");
//   mapPath = writable("http://www.memoryloops.org/map");
//   thumbPath = writable("http://www.memoryloops.org/assets/images/thumbs/");
//   downloadPath = writable("http://www.memoryloops.org/downloads/loops/");
//   download2Path = writable("http://www.memoryloops.net/de/download/");
// }

export let currentLoopURL = writable("https://memoryloops.net");
export let currentLoopNumber = writable("000-0");
export let currentLoopAddress = writable("Address");
// export let currentLoopGroup = writable(0);
export let currentLoopGroupArray = writable([]);
export let currentLoopGroupRef = writable(0);
export let currentLoopGroupID = writable(0);
export let currentLoopIsPlaying = writable(false);

export let currentHotspot = writable({});
export let focusedHotspot = writable({});

// export let hotspots = writable([]);

// currentLoopAddress.subscribe(value => {
//   console.log("|-- currentLoopAddress:", value);
// });

// export let currLoop = writable({
//   data: {},
//   id: "no loop selected",
//   label: "Einbahnstrasse 3",
//   url: "http://example.com",
//   keys: {},
//   duration: "",
//   associates: {},
//   pin_nr: "",
//   lang: ""
// });
// currLoop.subscribe(value => {
//   // console.log("|-- Update > currLoop:", value.id, value.label);
// });
// // currLoop.update(n => n + 1); // logs '2'

export let currentLoop = writable("");
currentLoop.subscribe((value) => {
  // console.log("|-- currentLoop:", value);
});

export let focusedLoop = writable("");
focusedLoop.subscribe((value) => {
  // console.log("|-- focusedLoop:", value);
});

// export const storedLoops = writable({ msg: "storedLoops" });
export const storedLoops = writable(memoryloops_de);
storedLoops.subscribe((value) => {
  // console.table("|-- storedLoops:", value);
});

export const storedGroups = writable(groups_de);
storedGroups.subscribe((value) => {
  // console.table("|-- storedGroups:", value);
});

// export let preview2 = writable({ eins: "eins", zwei: 2, drei: 33 });
// preview2.subscribe((value) => {
//   // console.table("|-- storedGroups:", value);
// });

// -------------------------------------------------------------------------

// export let krpano = writable({ eins: "1" });
export let krpano = writable();

export let loopPlayerOpen = writable(false);

export let loopListOpen = writable(false);

// -------------------------------------------------------------------------

let _lang = "";
export const lang = writable("de");
lang.subscribe((value) => {
  // if ($krpano) $krpano.call(`deleteAllHotspots();`);
  // app.$$.ctx.$krpano.call("deleteAllHotspots();");
  // deleteHotspots2();
  _lang = value;
  setStoredLoopsByLang();
  // console.log("|-- language:", _lang);
});

// -------------------------------------------------------------------------

export function getLoop(num) {
  // return _.filter(memoryloops_de, ["id", num]);
  return memoryloops.filter((item) => item.loop.id.toString().includes(num));
}
// console.log("|--- this loop ist:", getLoop(113));

// --------------------------------------------------------------------------------------
// ------------------------------------- SORTING ----------------------------------------
// Vanilla JS replacement for Lodash _.sortBy
// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_sortby-and-_orderby
// --------------------------------------------------------------------------------------

// export function sortLoopListBy(arr) {
//   console.log("|----- sortLoopListBy: ", arr);
//   for (let arg of arr) {
//     memoryloops_de.concat().sort(_sortBy(arg));
//   }
//   storedLoops.set(memoryloops_de);
// }
// sortLoopListBy(["text", "id"]);

//------------------------------------------------------------------
// export function reverseLoopList() {
//   console.log("|----- reverseLoopList: ", lang);
//   memoryloops_de = memoryloops_de.reverse();
//   memoryloops_en = memoryloops_en.reverse();
//   // storedLoops.set(memoryloops_de);
//   storedLoops.set(lang === "DE" ? memoryloops_de : memoryloops_en);
// }

//------------------------------------------------------------------

export const sortMethod = (key) => {
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

//------------------------------------------------------------------
export function sortBy(arr) {
  // console.log("|----- sortBy: ", _lang, arr);
  if (sortKeys === arr[0]) {
    // memoryloops_de = memoryloops_de.reverse();
    // memoryloops_en = memoryloops_en.reverse();
  } else {
    for (let arg of arr) {
      memoryloops_de = memoryloops_de.concat().sort(sortMethod(arg));
      memoryloops_en = memoryloops_en.concat().sort(sortMethod(arg));
    }
  }
  sortKeys = arr[0];
  setStoredLoopsByLang();
}

export function setStoredLoopsByLang() {
  storedLoops.set(_lang === "de" ? memoryloops_de : memoryloops_en);
}

// function dynamicSort(property) {
//   var sortOrder = 1;
//   if (property[0] === "-") {
//     sortOrder = -1;
//     property = property.substr(1);
//   }
//   return function (a, b) {
//     if (sortOrder == -1) {
//       return b[property].localeCompare(a[property]);
//     } else {
//       return a[property].localeCompare(b[property]);
//     }
//   };
// }

// -------------------------------------------------------------------------
// MENU ITEMS TRANSLATIN TABLE
// -------------------------------------------------------------------------

export const loc = writable({
  de: {
    m01: "Über",
    m02: "Hörspiel",
    m03: "Hilfe",
    m04: "Datenschutz",
    m05: "Impressum",
    m06: "EN",

    r01: "/ueber",
    r02: "/hoerspiel",
    r03: "/hilfe",
    r04: "/datenschutz",
    r05: "/impressum",
  },
  en: {
    m01: "About",
    m02: "Radio Play",
    m03: "Help",
    m04: "Privacy",
    m05: "Imprint",
    m06: "DE",

    r01: "/about",
    r02: "/radioplay",
    r03: "/help",
    r04: "/privacy",
    r05: "/imprint",
  },
});

// -------------------------------------------------------------------------
