<!-- -------------------------------------------------------------------------------------------- -->

<script>
    import {fade} from 'svelte/transition'
    import {fly} from 'svelte/transition'
    import { quadInOut } from 'svelte/easing';
</script>


<!-- -------------------------------------------------------------------------------------------- -->



<div class="info-content" in:fly="{{duration: 500, opacity: 0.0, easing: quadInOut}}">
    <div class="container" in:fly="{{delay: 100, duration: 500, y: 50, opacity: 0.0, easing: quadInOut}}">
        


        <section id="datenschutz">

            <h1>Datenschutz</h1>

            <p>Mit dem nachfolgenden Text möchten wir Sie über die Art, den Umfang und die Zwecke der Erhebung und Verwendung personenbezogener Daten innerhalb des Onlineangebotes unter http://www.memoryloops.net unterrichten.</p>

            <p>Sie können dieses Onlineangebot besuchen, ohne Angaben zu Ihrer Person zu machen. Wir speichern lediglich Zugriffsdaten ohne Personenbezug, wie z.B. den Namen Ihres Internet Service Providers, die Seite, von der aus Sie uns besuchen oder den Namen der angeforderten Datei. Diese Daten werden ausschließlich zur Verbesserung unseres Angebotes ausgewertet und erlauben keinen Rückschluss auf Ihre Person. Wir verarbeiten personenbezogene Daten der Nutzer nur unter Einhaltung der einschlägigen Datenschutzbestimmungen. Das bedeutet, die personenbezogenen Daten der Nutzer werden bei einem Besuch dieses Online-Angebots wie folgt verarbeitet:</p>
            
            <h4>Arten der verarbeiteten personenbezogenen Daten:</h4>
            <ul class=" list-disc list-inside">
                <li>Bestandsdaten (z.B. Name, Adresse – nur bei elektronischer Kontaktaufnahme)</li>
                <li>Kontaktdaten (z.B. E-Mail, Telefonnummer – nur bei elektronischer Kontaktaufnahme)</li>
                <li>Inhaltsdaten (z.B. Texteingaben, Bilddateien, Video-Dateien – nur bei elektronischer Kontaktaufnahme)</li>
                <li>Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten)</li>
                <li>Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen)</li>
            </ul>

            <h4>Kategorien betroffener Personen</h4>
            <p>Besucher und Nutzer des Onlineangebotes (nachfolgend gemeinsam bezeichnet als „Nutzer“).</p>
            
            <h4>Zweck der Verarbeitung:</h4>
            <ul class=" list-disc list-inside">
                <li>Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte – Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern</li>
                <li>Sicherheitsmaßnahmen</li>
                <li>Reichweitenmessung/Marketing</li>
            </ul>

            <p>Als verantwortungsbewusster Anbieter verzichten wir auf eine automatische Entscheidungsfindung oder ein Profiling.</p>

            <h4>1. Name / Anschrift des für die Verarbeitung Verantwortlichen</h4>
            <p>
                Landeshauptstadt München<br>
                vertreten durch den Kulturreferenten Anton Biebl<br>
                <br>
                Kulturreferat <br>
                Abteilung 1<br>
                Burgstraße 4<br>
                D-80331 München<br>
                <br>
                Tel: +49-(0)89-233-26087<br>
                Fax: +49-(0)89-233-21269<br>
                <br>
                E-Mail: <a href="mailto:kultur.foerderung@muenchen.de">kultur.foerderung@muenchen.de</a><br>
                <br>
                Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE129524000
                <br>
                Körperschaft des öffentlichen Rechts
                Zuständige Aufsichtsbehörde: Regierung von Oberbayern
                <br>
                Link zum Impressum: <a href="http://memoryloops.net/impressum">http://memoryloops.net/impressum</a> <br>
            </p>


            <h4>2. Behördliche Datenschutzbeauftragte</h4>

            <p>
                Landeshauptstadt München <br> 
                Behördlicher Datenschutzbeauftragter<br>
                Burgstraße 4<br>
                D-80331 München<br>
                Email: <a href="mailto:datenschutz@muenchen.de">datenschutz@muenchen.de</a><br>
            </p>


            <h4>3. Begriffsbestimmungen</h4>

            <p>Diese Datenschutzerklärung verwendet u.a. die folgenden Rechtsbegriffe, die der Europäische Richtlinien- und Verordnungsgeber bei der Datenschutz-Grundverordnung (DSGVO) verwendet und die dort in Art. 4 (Begriffsbestimmungen) wie folgt bestimmt sind:</p>
            <p>„Personenbezogene Daten“ bezeichnet alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.</p>        
            <p>„Verarbeitung“ bezeichnet jeden mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die</p>        
            <p>Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.</p>
            <p>„Verantwortlicher“ bezeichnet die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet; sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.</p>        
            <p>„Einwilligung“ der betroffenen Person bezeichnet jede freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist;</p>


            <h4>4. Zwecke und Rechtsgrundlagen für die Verarbeitung personenbezogener Daten</h4>

            <p>Zweck der Verarbeitung ist die Erfüllung der uns vom Gesetzgeber zugewiesenen öffentlichen Aufgaben.</p>
            <p>Die Rechtsgrundlage für die Verarbeitung Ihrer Daten ergibt sich, soweit nichts anderes angegeben ist, aus Art. 4 Abs. 1 des Bayerischen Datenschutzgesetzes (BayDSG) in Verbindung mit Art. 6 Abs. 1 Buchstabe e der Datenschutzgrundverordnung (DSGVO). Demnach ist es uns erlaubt, die zur Erfüllung einer uns obliegenden Aufgabe erforderlichen Daten zu verarbeiten.</p>
            <p>Soweit Sie in eine Verarbeitung eingewilligt haben, stützt sich die Datenverarbeitung auf Art. 6 Abs. 1 Buchstabe a DSGVO.</p>
            <p>Rechtsgrundlage für die Datenverarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f) DSGVO.</p>


            <h4>5. Zusammenarbeit mit Auftragsverarbeitern und Dritten</h4>

            <p>Sofern wir im Rahmen unserer Datenverarbeitung Daten gegenüber anderen Personen und Unternehmen (Auftragsverarbeitern oder Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf Daten gewähren, erfolgt dies auf Grundlage einer gesetzlichen Erlaubnis oder auf Grund Ihrer Einwilligung oder weil eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz eines Hosting-Providers, etc.). Sofern wir Dritte mit der Verarbeitung von personenbezogenen Daten auf Grundlage eines sog. „Auftragsverarbeitungsvertrages“ beauftragen, erfolgt dies auf Grundlage des Art. 28 DSGVO.</p>
            

            <h4>6. Übermittlungen in Drittländer</h4>

            <p>Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter durch Offenlegung bzw. Übermittlung von Daten an Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse verarbeiten oder lassen wir die Daten in einem Drittland nur beim Vorliegen der besonderen Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen (so genannte „Standardvertragsklauseln“).</p>


            <h4>7. Rechte der betroffenen Personen</h4>

            <p>Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten umfassende Betroffenenrechte (Auskunfts- und Interventionsrechte), über die wir Sie nachstehend informieren:</p>
            
            <p><u>Auskunftsrecht gemäß Art. 15 DSGVO:</u>
            <br>Die betroffene Person hat das Recht, von dem Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden; ist dies der Fall, so hat sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf folgende Informationen:</p>

            <ul class=" list-inside">
                <li>a) die Verarbeitungszwecke</li>
                <li>b) die Kategorien personenbezogener Daten, die verarbeitet werden</li>
                <li>c) die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen</li>
                <li>d) falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer</li>
                <li>e) das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung</li>
                <li>f) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
                <li>g) wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten;</li>
                <li>h) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel 22 Absätze 1 und 4 DSGVO und — zumindest in diesen Fällen — aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.</li>
            </ul>

            <br> 

            <p><u>Recht auf Berichtigung gemäß Art. 16 DSGVO:</u>
            <br>Sie haben ein Recht auf unverzügliche Berichtigung Sie betreffender unrichtiger Daten und/oder Vervollständigung Ihrer bei uns gespeicherten unvollständigen Daten;</p>

            <p><u>Recht auf Löschung gemäß Art. 17 DSGVO:</u>
            <br>Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten bei Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</p>
            
            <p><u>Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO:</u>
            <br>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange die von Ihnen bestrittene Richtigkeit Ihrer Daten überprüft wird, wenn Sie eine Löschung Ihrer Daten wegen unzulässiger Datenverarbeitung ablehnen und stattdessen die Einschränkung der Verarbeitung Ihrer Daten verlangen, wenn Sie Ihre Daten zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, nachdem wir diese Daten nach Zweckerreichung nicht mehr benötigen oder wenn Sie Widerspruch aus Gründen Ihrer besonderen Situation eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gründe überwiegen;</p>
            
            <p><u>Recht auf Unterrichtung gemäß Art. 19 DSGVO:</u> 
            <br>Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht das Recht zu, über diese Empfänger unterrichtet zu werden.</p>
            
            <p><u>Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO:</u> 
            <br>Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen, soweit dies technisch machbar ist;</p>
            
            <p><u>Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DSGVO:</u>
            <br>Sie haben das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung für die Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten unverzüglich löschen, sofern eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung gestützt werden kann. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt;</p>
            
            <p><u>Recht auf Beschwerde gemäß Art. 77 DSGVO:</u>
            <br>Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, haben Sie – unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs – das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des mutmaßlichen Verstoßes.</p>     
            
            <p><u>Widerspruchsrecht gemäß Art. 21 DSGVO:</u> 
            <br>Sie können der künftigen Verarbeitung der Sie betreffenden personenbezogenen Daten nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Direktwerbung erfolgen.</p>


            <h4>8. Beschwerderecht bei der Aufsichtsbehörde</h4>
            
            <p>Weiterhin besteht ein Beschwerderecht beim Bayerischen Landesbeauftragten für den Datenschutz. Diesen können Sie unter folgenden Kontaktdaten erreichen:</p>        
            <p>
                <u>Postanschrift:</u> <br>
                Postfach 22 12 19, <br>
                80502 München<br>
                <br>
                <u>Adresse:</u> <br> 
                Wagmüllerstraße 18 <br> 
                80538 München<br>
                <br>
                Tel: +49-(0)89-212672-0<br>
                Fax: +49-(0)89-212672-50<br>
                <br>
                Email: <a href="mailto:poststelle@datenschutz-bayern.de">poststelle@datenschutz-bayern.de</a><br>
                Internet: <a href="https://www.datenschutz-bayern.de">https://www.datenschutz-bayern.de</a>
            </p>


            <h4>9. Cookies</h4>
            
            <p>Um den Besuch unseres Onlineangebots attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir sogenannte Cookies. Cookies sind Textdateien, welche über einen Internetbrowser auf einem Computersystem/Rechner eines Nutzers abgelegt und gespeichert werden. Innerhalb der Cookies können unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder auch nach seinem Besuch innerhalb eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“ werden Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser schließt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein Login-Staus gespeichert werden. Als „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach dem Schließen des Browsers gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen der Nutzer gespeichert werden, die für Reichweitenmessung oder Marketingzwecke verwendet werden. Als „Third-Party-Cookies“ werden Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen, der das Onlineangebot betreibt, angeboten werden (andernfalls, d.h. wenn es nur dessen Cookies sind spricht man von „First-Party Cookies“).</p>
            <p>Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen unserer Datenschutzerklärung wie folgt auf:</p>
            <p>Falls ein Nutzer nicht möchte, dass Cookies auf seinem Rechner gespeichert werden, muss er die entsprechende Option in den Systemeinstellungen seines Browsers zu deaktivieren. Bereits gespeicherte Cookies können in den Systemeinstellungen seines Browsers gelöscht werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen dieses Onlineangebotes führen.</p>
            
            
            <h4>10. Kontaktaufnahme</h4>
            
            <p>Informationen, die Sie unverschlüsselt per Elektronische Post (E-Mail) an uns senden, können möglicherweise auf dem Übertragungsweg von Dritten gelesen werden. Wir können in der Regel auch Ihre Identität nicht überprüfen und wissen nicht, wer sich hinter einer E- Mail-Adresse verbirgt. Eine rechtssichere Kommunikation durch einfache E-Mail ist daher nicht gewährleistet. Wir setzen – wie viele E-Mail-Anbieter – Filter gegen unerwünschte Werbung („SPAM-Filter“) ein, die in seltenen Fällen auch normale E-Mails fälschlicherweise automatisch als unerwünschte Werbung einordnen und löschen. E-Mails, die schädigende Programme („Viren“) enthalten, werden von uns in jedem Fall automatisch gelöscht.</p>
            <p>Wenn Sie schutzwürdige Nachrichten an uns senden wollen, empfehlen wir, diese zu verschlüsseln und zu signieren, um eine unbefugte Kenntnisnahme und Verfälschung auf dem Übertragungsweg zu verhindern oder die Nachricht auf konventionellem Postwege an uns zu senden. Bitte teilen Sie uns auch mit, ob und auf welche Weise wir Ihnen zur Beantwortung Ihrer Zusendungen verschlüsselte E-Mails übersenden können. Wenn Sie über keine Möglichkeit zum Empfang verschlüsselter E-Mails verfügen, bitten wir uns zur Beantwortung Ihrer schutzwürdiger Nachrichten Ihre Postanschrift zu nennen.</p>    
            <p>Im Rahmen der Kontaktaufnahme mit uns (z.B. E-Mail) werden personenbezogene Daten erhoben. Diese Daten werden ausschließlich zum Zweck der Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage für die Verarbeitung der Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>


            <h4>11. Löschung von Daten / Einschränkung der Verarbeitung</h4>
            
            <p>Von uns verarbeitete personenbezogene Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern im Rahmen dieser Datenschutzerklärung nicht anderes ausdrücklich angegeben ist, werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen (z.B. § 257 HGB, § 147 Abs. 1 AO – Aufbewahrung bis zu 10 Jahre) aufbewahrt werden müssen. Sofern die Daten nicht gelöscht werden, weil sie für andere, gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet.</p>


            <h4>12. Hosting / Erhebung von Zugriffsdaten und Logfiles</h4>
            
            <p>Die von uns bei unserem Hosting-Provider in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen unseres Online-Angebots: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes einsetzen. Hierbei verarbeiten wir bzw. unser Hosting-Provider Kontaktdaten (bei E-Mail-Kontakt: E-Mail-Adresse), Nutzungsdaten, Meta- und Kommunikationsdaten von Nutzern dieses Onlineangebotes auf der Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).</p>
            <p>Wir bzw. unser Hosting-Provider erfassen mit jedem Aufruf unseres Onlineangebots durch eine betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles des Servers gespeichert. Erfasst werden können die (1) verwendeten Browsertypen und Versionen, (2) das vom zugreifenden System verwendete Betriebssystem, (3) die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite gelangt (sogenannte Referrer), (4) die Unterwebseiten, welche über ein zugreifendes System auf unserer Internetseite angesteuert werden, (5) das Datum und die Uhrzeit eines Zugriffs auf die Internetseite, (6) eine Internet-Protokoll-Adresse (IP-Adresse), (7) der Internet-Service- Provider des zugreifenden Systems und (8) sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von Angriffen auf unsere informationstechnologischen Systeme dienen.</p>    
            <p>Bei der Nutzung dieser allgemeinen Daten und Informationen ziehen wir bzw. unser Hosting- Provider keine Rückschlüsse auf die betroffene Person. Diese Informationen werden vielmehr benötigt, um (1) die Inhalte unserer Internetseite korrekt auszuliefern, (2) die Inhalte unserer Internetseite sowie die Werbung für diese zu optimieren, (3) die dauerhafte Funktionsfähigkeit unserer informationstechnologischen Systeme und der Technik unserer Internetseite zu gewährleisten sowie (4) um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen bereitzustellen. Die Daten der Server-Logfiles werden getrennt von etwaigen durch eine betroffene Person angegebenen personenbezogenen Daten gespeichert.</p>
            <p>Diese Logfile-Informationen werden für die Dauer von maximal 60 Tagen gespeichert und danach gelöscht.</p>

            <!--
            <h4>13. Webanalysedienst Matomo</h4>
            
            <p>Wir verarbeiten auf unserer Website unter Einsatz der Webanalysedienst-Software Matomo (www.matomo.org), einem Dienst des Anbieters InnoCraft Ltd., 150 Willis St, 6011 Wellington, Neuseeland, („Mataomo“) auf Basis unseres berechtigten Interesses an der statistischen Analyse des Nutzerverhaltens zu Optimierungs- und Marketingzwecken gemäß Art. 6 Abs. 1 lit. f DSGVO Daten.</p>
            <p>Die mit der Matomo-Technologie erhobenen Daten (einschließlich Ihrer pseudonymisierten IP-Adresse) werden ausschließlich auf unseren Servern verarbeitet und nicht an Dritte weitergegeben. Aus diesen Daten können zum eben genannten Zweck pseudonymisierte Nutzungsprofile erstellt und ausgewertet werden. Hierzu können Cookies eingesetzt werden. Die durch das Cookie erzeugten Informationen im pseudonymen Nutzerprofil werden nicht dazu benutzt, den Besucher dieser Website persönlich zu identifizieren und nicht mit personenbezogenen Daten über den Träger des Pseudonyms zusammengeführt.</p>
            <p>Wenn Sie mit der Speicherung und Auswertung dieser Daten aus Ihrem Besuch nicht einverstanden sind, dann können Sie der Speicherung und Nutzung nachfolgend per Mausklick jederzeit widersprechen.</p>
            <p>Hier klicken, um der Speicherung zu widersprechen.</p>
            <p>In diesem Fall wird in Ihrem Browser ein sog. Opt-Out-Cookie abgelegt, was zur Folge hat, dass Matomo keinerlei Sitzungsdaten erhebt. Bitte beachten Sie, dass die vollständige Löschung Ihrer Cookies zur Folge hat, dass auch das Opt-Out-Cookie gelöscht wird und ggf. von Ihnen erneut aktiviert werden muss.</p>


            <h4>14. Social Media</h4>
            
            <p>Social Media Schaltflächen („Gefällt mir“-buttons) sind nicht direkt, sondern über das Open Source Datenschutz-Projekt „Shariff“ eingebunden. Dadurch ist sichergestellt, dass durch das Aufrufen und Betrachten einer Internet-Seite der Landeshauptstadt München keine Daten auf Server von Social Media Anbietern übertragen werden.</p>
            <p>Daneben finden sich nur einfache Links, die auf eine entsprechende dortige Website führen (z.B. Fanseite der Landeshauptstadt München).</p>
            <p>Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook, Google, Twitter oder sonstigen Social Media Anbietern sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen des jeweiligen Social-Media-Anbieters.</p>
            (ALTERNATIVE)

            <h4>14. Onlinepräsenzen in sozialen Medien</h4>
            
            <p>Wir sind in dem sozialen Netzwerk Facebook aktiv, welches von der Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird („Facebook“).</p>
            <p>Geben Sie zu Ihrem eigenen Schutz in der öffentlichen Kommunikation über Social Media keine sensiblen Daten an.</p>
            <p>Bitte beachten Sie, dass wir keinen Einfluss auf die Nutzungsbedingungen des sozialen Netzwerks haben. Wir machen Sie ausdrücklich darauf aufmerksam, dass Facebook Daten</p>
            <p>außerhalb Deutschlands dauerhaft speichert und für geschäftliche Zwecke nutzt. In welchem Umfang und für welche Dauer die Daten gespeichert werden, ist für uns nicht erkennbar. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie die diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer, können diese den Datenschutzhinweisen von Facebook entnehmen: https://www.facebook.com/about/privacy/.</p>
            <p>Wir empfehlen Ihnen daher, sorgfältig zu prüfen, welche persönlichen Daten Sie den sozialen Netzwerken zur Verfügung stellen. Wir behandeln unsererseits Ihre Daten mit höchster Sorgfalt, übernehmen aber keine Haftung für das Verhalten der Betreiberinnen bzw. Betreiber der sozialen Netzwerke sowie Dritter.</p>

            -->

            <h4>13. Sicherheitsmaßnahmen</h4>
            
            <p>Wir treffen organisatorische, vertragliche und technische Sicherheitsmaßnahmen entsprechend dem Stand der Technik, um sicherzustellen, dass die Vorschriften der Datenschutzgesetze eingehalten werden und um damit die durch uns verarbeiteten Daten gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder gegen den Zugriff unberechtigter Personen zu schützen.</p>
            <p>Bei allen Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Sperrung oder Löschung von Daten sowie Widerruf erteilter Einwilligungen wenden Sie sich bitte an die Impressum angegebene E-Mail-Adresse.</p>
            
        </section>

        <br>
        <br>
        <br>
        <br>
        <br>
        <br>

    </div>
</div>
