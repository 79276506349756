<!-- -------------------------------------------------------------------------------------------- -->

<script>
    import {fade} from 'svelte/transition'
    import {fly} from 'svelte/transition'
    import { quadInOut } from 'svelte/easing';
    import { download2Path } from '../Store/store.js';
    let anim1 = {delay: 100};
</script>



<!-- -------------------------------------------------------------------------------------------- -->


<div class="info-content" in:fly="{{duration: 500, opacity: 0.0, easing: quadInOut}}">
    <div class="container" in:fly="{{delay: 100, duration: 500, y: 50, opacity: 0.0, easing: quadInOut}}">

        <!-- --------------------------------------------------------------------------------- --> 

        <section id="ueber">

            <div class="md:flex" in:fly="{{delay: 0}}">
                <div class="md:w-1/2">
                    <h1>Über</h1>
                </div>
                <div class=":mdw-1/2">
                </div>
            </div>

            <div class="md:flex mb-8" in:fade="{{delay: 300}}" >
                <div class="md:w-1/2 md:pr-4">
                    <p class="text-white">München realisiert mit <i>Memory Loops</i> ein virtuelles Denkmal für die Opfer des Nationalsozialismus. Das Audiokunstwerk von Michaela Melián umfasst 300 deutsche und 175 englische Tonspuren, die zum Anhören und kostenlosen Download auf einer virtuellen Stadtkarte hinterlegt sind. Jede Tonspur ist eine Collage aus Stimme(n) und Musik, die thematisch einem Ort innerhalb der ehemaligen „Hauptstadt der Bewegung“ zugeordnet ist.</p>
                    <p>
                    <!-- <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Weitere Informationen
                    </button><br>
                    <button>Presse und Archiv</button> -->
                    <!-- <a class="bttn text-accentColor" href="/#weitere-informationen">Weitere Informationen</a><br> -->
                    <!-- <a class="bttn text-accentColor" href="/#presse-und-archiv">Presse und Archiv</a>  -->
                    </p>
                </div>
                <div class="md:w-1/2  md:pr-4">
                    <p>Mit ihrem Konzept <i>Memory Loops</i> gewann Michaela Melián 2008 den Kunstwettbewerb der Landeshauptstadt München „Opfer des Nationalsozialismus – Neue Formen des Erinnerns und Gedenkens“. Das Projekt wurde in Zusammenarbeit mit dem Bayerischen Rundfunk / Hörspiel und Medienkunst verwirklicht.</p>
                    <p>Memory Loops basiert auf historischen und aktuellen Originaltönen von NS-Opfern und Zeitzeugen: Zeugnisse von Diskriminierung, Verfolgung und Ausgrenzung während des NS-Regimes in München. Die transkribierten Berichte werden von Schauspielerinnen und Schauspielern gesprochen, historische Dokumente von Kindern gelesen, die Aufnahmen in eine originale Musikkomposition eingebettet.</p>
                </div>
            </div>

        </section>

        <hr> <!-- --------------------------------------------------------------------------------- -->        
        
        <section id="weitere-informationen">

            <div class="md:flex" in:fly="{{delay: 0}}">
                <div class="md:w-1/2">
                    <h1>Weitere Informationen</h1>
                </div>
                <div class="md:w-1/2">
                </div>
            </div>

                <details  >
                    <summary><i>Memory Loops</i> – Erinnerungsschleifen selbst zusammenstellen</summary>
                    <p>
                        Das Zentrum des Kunstwerks ist die Webseite memoryloops.net, auf der die gesammelten Erinnerungszeugnisse als Tonspuren in einer von der Künstlerin gezeichneten Topographie der Stadt zu finden sind. Die Collagen aus Stimme(n) und Musik verweisen thematisch auf Orte innerhalb der ehemaligen „Hauptstadt der Bewegung“. Sie können <a href="{$download2Path}Memory_Loops_Alle_Hoerspiele.zip">heruntergeladen</a> werden. Ob auf ein Mobiltelefon oder auf einen MP3-Player überspielt: Alle Interessierten können so ihrer selbst gewählten Erinnerungsschleife durch die Stadt folgen. 
                        <br>
                        Fünf der 300 Tonspuren sind einstündige Erinnerungsschleifen, die sich über den ganzen Stadtraum legen und Themenschwerpunkte haben. Sie sind unter der Rubrik <a href="/#/hoerspiel">Hörspiel</a> zu finden. Auch in englischer Sprache liegt ein einstündiges <a href="/#/hoerspiel">Hörspiel</a> vor.
                    </p>
                </details>

                <details >
                    <summary><i>Memory Loops</i> im Münchner Stadtraum</summary>
                
                    <p>An 61 Standorten in der Münchner Innenstadt sind Hinweistafeln mit URL und Telefonnummer zu Tonspuren angebracht, die auf den jeweiligen Ort Bezug nehmen. Falls also keine Internetverbindung möglich ist, können einzelne Tonspuren auf Deutsch und Englisch jederzeit über eine Telefonverbindung ins Festnetz angehört werden.</p>
                
                    <ul class="downloads list-inside">
                        <li>
                            <a target="_blank" href="{$download2Path}ML_Schilder_Lageplan.pdf">Lageplan der Schilder und Ausleihstationen</a>
                        </li>
                    </ul>
                </details>

                <details >
                    <summary>Der Wettbewerb</summary>
                    <p>
                        Mit Memory Loops hat Michaela Melián den <a href="https://www.muenchen.de/rathaus/Stadtverwaltung/Kulturreferat/Bildende-Kunst/Kunst-im-oeffentlichen-Raum/Memory-Loops.html">Kunstwettbewerb</a> der Landeshauptstadt München „Opfer des Nationalsozialismus – Neue Formen des Erinnerns und Gedenkens“ gewonnen. Das Projekt wird in Zusammenarbeit mit dem Bayerischen Rundfunk / Hörspiel und Medienkunst realisiert.
                    </p>

                    <h4>Aus der Begründung der Jury</h4>
                    <p>
                        „Die Arbeit von Michaela Melián thematisiert in anspruchsvoller
                        künstlerischer Form das Schicksal aller Opfer des Nationalsozialismus.
                        Gleichzeitig werden die Täter nicht aus der Erinnerung ausgeklammert.
                        Unterschiedlichste historische Zusammenhänge werden an
                        Kristallisationspunkten der Stadt München erörtert. Aus
                        Archivmaterialien und Aussagen von Zeitzeugen werden künstlerisch
                        gestaltete Stimmencollagen in höchster Qualität erstellt. Dabei findet
                        sich in Recherche und Realisierung der Audiocollagen eine breite
                        institutionelle Vernetzung, wobei bereits hier die Integration jüngerer
                        Generationen berücksichtigt wird.
                    </p>
                    <p>
                        Das Kunstwerk ist sowohl dezentral angelegt als auch auf historisch
                        bedeutsame Orte bezogen. Denn mit Hilfe von auf Mobiltelefon oder
                        anderen Tonträgern zu erreichenden Memory Loops (Stimmcollagen) wird
                        eine allgegenwärtige moderne Form des Erinnerns und Gedenkens
                        geschaffen. Mit ihrem Konzept des Dezentralen, Individuellen,
                        Immateriellen und Temporären sowie einem hohen Partizipationsanteil des
                        Publikums eröffnet Melián individuelle Erkenntnis- und
                        Erfahrungsmöglichkeiten, die sich – auf dem Boden von Humanismus und
                        Demokratie – von Deutungshoheiten etwa eines Expertentums abheben.
                        Meliáns Projekt formuliert einen appellativen Aufruf zur Menschlichkeit
                        und Demokratie angesichts der Gräueltaten im Nationalsozialismus –
                        behutsam, aber eindringlich.“
                    </p>

                    <h4>Die Fachjurorinnen und Fachjuroren</h4>

                    <ul class=" list-disc list-inside">
                        <li>Prof. Liz Bachhuber, Bauhaus Universität Weimar</li>
                        <li>Chris Dercon, Direktor Haus der Kunst München</li>
                        <li>Dr. Cornelia Gockel, Kunstkritikerin, München</li>
                        <li>Prof. Dr. Raphael Gross, Direktor Leo Baeck Institute London, <br>Jüdisches Museum und Fritz Bauer Institut, Frankfurt am Main</li>
                        <li>Prof. Dr. Kai-Uwe Hemken, Kunsthochschule Kassel</li>
                        <li>Dr. Angelika Nollert, Direktorin Neues Museum Nürnberg</li>
                    </ul>

                </details>

                <details >
                    <summary>Michaela Melían</summary>

                    <p>Künstlerin und Musikerin, lebt in München und Hamburg. Sie lehrt als Professorin für zeitbezogene Medien an der Hochschule für bildende Künste (HfbK), Hamburg. Sie ist Mitglied der Band F.S.K. (Freiwillige Selbstkontrolle).</p>
                    <p>2008 gewann Michaela Melián mit ihrem Konzept <i>Memory Loops</i> den von der Landeshauptstadt München initiierten <a target="_blank" href="https://www.muenchen.de/rathaus/Stadtverwaltung/Kulturreferat/Bildende-Kunst/Kunst-im-oeffentlichen-Raum/Memory-Loops.html">Kunstwettbewerb</a> „Opfer des Nationalsozialismus – Neue Formen des Erinnerns und Gedenkens“.</p>
                    
                    <h4>Ausstellungsauswahl</h4>
                    <p><i>Home Less Home</i>, Museum on the Seam, Jerusalem (2010); <i>See this Sound</i>, Lentos Museum Linz (2010); Ludlow 38, New York (2009); <i>The Dwelling</i>, ACCA Melbourne, Australien (2009); <i>Speicher</i> , Ulmer Museum (2008); <i>Speicher</i>, Cubitt Gallery, London (2008); <i>Recollecting</i>, MAK Vienna (2008); <i>Vertrautes Terrain</i> , ZKM Karlsruhe (2007); <i>TALK/SHOW</i>, k.m@tranzit dielne, Bratislava (2007); <i>Föhrenwald</i>, KW Kunstwerke Berlin (2006)</p>

                    <h4>Hörspiele</h4>
                    <ul class=" list-disc list-inside">
                        <li><i>Speicher</i> <span class="tab"></span>(BR und Münchner Kammerspiele 2008)</li>
                        <li><i>Föhrenwald</i> <span class="tab"></span>(BR / kunstraum münchen 2005)</li>
                        <li><i>Konvent</i> <span class="tab"></span>(mit Thomas Meinecke und David Moufang, BR / ZKM / intermedium 2, 2002)</li>
                    </ul>
                    
                    <h4>Preise</h4>
                    <ul class="list-disc list-inside">
                        <li>2011 <span class="tab">Hörspiel des Jahres der Deutschen Akademie der Darstellenden Künste für <i>Memory Loops</i></li>
                        <li>2010 <span class="tab">Kunstpreis der Landeshauptstadt München</li>
                        <li>2009 <span class="tab">Hörspiel des Jahres der Deutschen Akademie der Darstellenden Künste für <i>Speicher</i></li>
                        <li>2006 <span class="tab">Hörspielpreis der Kriegsblinden / Preis für Radiokunst für <i>Föhrenwald</i></li>
                        <li>2005 <span class="tab">Deutscher Hörspielpreis der ARD sowie ARD-Online Award für <i>Föhrenwald</i></li>
                    </ul>
                    <br>
                    <p>„Ihr Werk und ihr Umgang mit sowohl realem als auch metaphorischem Raum ist so sparsam wie umfassend, sicher und ziemlich berührend.“<br>
                    Roberta Smith, <i>New York Times</i></p>
                    
                    <p>„Michaela Melián setzt sich nun schon seit vielen Jahren mit Politiken der Erinnerung auseinander. Ihre Arbeiten geben Vorlagen für eine besondere Form der Annäherung an die Vergangenheit: Melián vereint die geschärfte Sensibilität dekonstruktiver Kritik dem starken Anliegen, bestimmten Menschen Anerkennung dafür zuteil werden zu lassen, dass sie durch ihr Leben und ihre Arbeit einen Unterschied gemacht haben.“ Jan Verwoert, <br><i>Frieze Magazine</i>, London</p>
                    
                    <p>„Michaela Meliáns künstlerische Aneignung von Geschichte erinnert an Benjamins geschichtsphilosophisches Konzept des dialektischen Bildes, bei dem Vergangenes und Gegenwärtiges in eine Konstellation treten, die deren Verhältnis blitzhaft erhellt.“ <br>Petra Löffler, <i>Springerin, Hefte für Gegenwartskunst</i>, Wien</p>

                </details>

        </section>

        <br>
        <hr> <!-- ---------------------------------------------------------------------------- -->
        

        <section id="presse-und-archiv">
            
            <div class="md:flex" in:fly="{{delay: 0}}">
                <div class="w-1/2">
                    <h1>Presse & Archiv</h1>
                </div>
                <div class="w-1/2">
                </div>
            </div>

            <!-- <div class="md:flex" in:fly="{anim1}" >
                <details  >
                    <summary>Pressekontakt</summary>
                    Pressestelle des Kulturreferats der Landeshauptstadt München:<br>
                    Jennifer Becker<br>
                    <a href="mailto:presse.kulturreferat@muenchen.de">presse.kulturreferat@muenchen.de</a><br> 
                    Tel: 089-233-26005<br>
                    Burgstraße 4, 80331 München
               </details>
            </div> -->

            <div class="md:flex" in:fly="{{delay: 200}}" >
                <details >
                    
                    <summary>Pressematerial</summary>
                    <ul class="downloads list-inside">
                        Sie können ein komplettes Paket aller bereitgestellten Dateien hier herunterladen:<br>
                        <li>
                            <a href="{$download2Path}Memory_Loops_Press_Materials.zip">Memory Loops Presse Materialien</a> (zip, 49mb)
                            <!-- <a href="http://www.memoryloops.net/press/Memory_Loops_Press_Materials.zip">Memory Loops Presse Materialien</a> (zip, 49mb) -->
                        </li>
                    </ul>

                    <h4>Bildmaterial</h4>
                    <p>Die Verwendung der Bilder und Motive ist im Rahmen der Berichterstattung über das Projekt <i>Memory Loops</i> honorarfrei.<br>
                    Bei Veröffentlichungen ist durch Angabe der Quelle und der Verwendung des Copyrights die Herkunft des Bildes eindeutig kenntlich zu machen:<br>
                    »Copyright: Michaela Melián & Surfacegrafik.de | Memory Loops 2010«</p>

                    <h4>Website</h4>
                    <ul class="downloads list-inside">
                        <li>
                            Motiv 1: Start – 
                            <a href="{$download2Path}ml_browser_01_start_highres_4c_DE.zip">4-farb Print jpg (4mb) </a> / 
                            <a href="{$download2Path}ml_browser_01_start_lowres_rgb_DE.zip">RGB Screen jpg (770kb) </a>
                        </li>
                        <li>
                            Motiv 2: Ortsmarkierung – 
                            <a href="{$download2Path}ml_browser_02_player_small_highres_4c_DE.zip">4-farb Print jpg (4mb)</a> / 
                            <a href="{$download2Path}ml_browser_02_player_small_lowres_rgb_DE.zip">RGB Screen jpg (760kb)</a>
                        </li>
                        <li>
                            Motiv 3: Player – 
                            <a href="{$download2Path}ml_browser_03_player_reg_highres_4c_DE.zip">4-farb Print jpg (3,6mb)</a> / 
                            <a href="{$download2Path}ml_browser_03_player_reg_lowres_rgb_DE.zip">RGB Screen jpg (540kb)</a>
                        </li>
                        <li>
                            Motiv 4: Hereingezoomt – 
                            <a href="{$download2Path}ml_browser_04_closeup_highres_4c_DE.zip">4-farb Print jpg (2,9mb)</a> / 
                            <a href="{$download2Path}ml_browser_04_closeup_lowres_rgb_DE.zip">RGB Screen jpg (300kb)</a>
                        </li>
                    </ul>

                    <h4>Mobile Website</h4>
                    <ul class="downloads list-inside">
                        <li>
                            Motiv 1: Detail – 
                            <a href="{$download2Path}memoryloops_mobile_detail_highres_4c_DE.zip">4-farb Print jpg (2,7mb)</a> / 
                            <a href="{$download2Path}memoryloops_mobile_detail_lowres_rgb_DE.zip">RGB Screen jpg (235kb)</a>
                        </li>
                        <li>
                            Motiv 1: Liste –  
                            <a href="{$download2Path}memoryloops_mobile_list_highres_4c_DE.zip">4-farb Print jpg (2,7mb)</a> / 
                            <a href="{$download2Path}memoryloops_mobile_list_lowres_rgb_DE.zip">RGB Screen jpg (221kb)</a>
                        </li>
                    </ul>

                    <h4>Kampagnenmotiv</h4>
                    <ul class="downloads list-inside">
                        <li>
                            Kampagnenmotive Deutsch – 
                            <a href="{$download2Path}memoryloops_general_ad_highres_4c_DE.pdf" target="_blank" >Print 4C PDF (5mb)</a> /
                            <a href="{$download2Path}memoryloops_title_highres_4c_DE.zip">Print 4C JPG (9,4mb)</a> /
                            <a href="{$download2Path}memoryloops_title_lowres_rgb_DE.zip">Screen RGB JPG (470kb)</a>
                             
                        </li>
                        <li>
                            Kampagnenmotive Englisch – 
                            <a href="{$download2Path}memoryloops_general_ad_highres_4c_EN.pdf" target="_blank" >Print 4C PDF (5mb)</a> / 
                            <a href="{$download2Path}memoryloops_title_highres_4c_EN.zip">Print 4C JPG (9,4mb)</a> / 
                            <a href="{$download2Path}memoryloops_title_lowres_rgb_EN.zip">Screen RGB JPG (470kb)</a>
                        </li>
                        <li>
                            Kampagnenmotiv – 
                            <a href="{$download2Path}Hoerspiel_des_Jahres_2010.zip"> Hörspiel des Jahres</a>
                        </li>
                    </ul>

                    <h4>Texte</h4>
                    <ul class="downloads list-inside">
                        <li>
                            <a target="_blank" href="{$download2Path}Pressetext_MemoryLoops_kurz.pdf">Kurzer Pressetext (pdf, deutsch)</a>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Pressetext_MemoryLoops_lang.pdf">Langer Pressetext (pdf, deutsch)</a>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Pressetext_MemoryLoops_short.pdf">Kurzer Pressetext (pdf, englisch)</a>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Pressetext_MemoryLoops_long.pdf">Langer Pressetext (pdf, englisch)</a>
                        </li>
                    </ul>

                </details>
            </div>

            <div class="md:flex" in:fly="{{delay: 200}}" >

                <details >
                    <summary>Pressearchiv (Auswahl)</summary>
                    <ul class="downloads list-inside">
                        <li>
                            <a target="_blank" href="{$download2Path}Prism_2012-4.pdf">Prism – I will survive you all (Spring 2012, Volume 4)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}prix-europa.pdf">Prix Europa – Special Commendation – Online Category</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}FAZ-30.Maerz2011.pdf">Frankfurter Allgemeine Zeitung – Feuilleton – Hinter jedem Hörkreis wartet ein Schicksal (30. März 2011)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Hoerspiel_des_Jahres_2010.pdf">Hörspiel des Jahres 2010 – Deutsche Akademie der Darstellenden Künste (März 2011)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Ich_bin_argwoehnisch_gegenueber_jeder_staatstragenden_Metaphorik_Texte_zur_Kunst_4.1.2011.pdf">Texte zur Kunst – Ich bin argwöhnisch gegenüber jeder staatstragenden Metaphorik (04. Jan. 2011)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Shoa.de.pdf">Shoa.de – Der Sound der Erinnerung (Januar 2011)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Website_JuedischesMuseumMuenchen.pdf">Jüdisches Museum Muenchen – Website – Erinnerungsschleifen (Januar 2011)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}taz_27November2010.pdf">Taz – Die Tageszeitung – Tonspuren der Vergangenheit (27. Nov. 2010)<br></a>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Memory_Loops_in_Muenchen_Das_Unbehagen_am_geregelten_Gedenken_-_Kunst_-_Feuilleton_-_FAZ.NET.pdf">Frankfurter Allgemeine Zeitung – Feuilleton – Das Unbehagen am geregelten Gedenken (19. Nov. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}KunstForum_International_Bd_205_Nov_2010_Memory_Loops.pdf">KUNSTFORUM international (Bd 205, November 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}konkret_Heft_11_2010.pdf">Konkret – Sie müssen was gesehen haben (Heft 11 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Sueddeutsche_Feuilleton_Melian_12_10_2010.pdf">Süddeutsche Zeitung – Feuilleton – Erinnerungsschleifen (12. Okt. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}D_Welle_english_12Oktober2010.pdf">Deutsche Welle – DW-english – Digital memorial – (12. Okt. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}DeutscheWelle-World.de_5.10.2010.pdf">Deutsche Welle – DW-WORLD.DE – Virtuelles Denkmal (5. Okt. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}ZDF_Woche_im_Web_2Oktober2010.pdf">ZDF – Die Woche im Web (2. Okt. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Monopol_15.10.2010.pdf">Monopol – Interpol – Playlist der Vergangenheit (Oktober 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Hoerspiel_des_Monats_Oktober_2010.pdf">Hörspiel des Monats Oktober 2010 – Deutsche Akademie der Darstellenden Künste (November 2011)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Jud_Allgemeine_30September2010.pdf">Jüdische Allgemeine – Kultur – 300 Tonspuren (30. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}FAZ_27.Sept.2010.pdf">Frankfurter Allgemeine Zeitung – Wer Ohren hat, der höre (27. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}kulturSPIEGEL_27September2010.pdf">Kultur SPIEGEL – Kunst – Erinnerung im Loop (27. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Spiegel_online.pdf">Spiegel ONLINE – Kunst – Erinnerung im Loop (27. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Spex_24.9.2010.pdf">Spex – Schleifen der Erinnerung (24. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}merkur_24.09.2010.pdf">Münchner Merkur – Feuilleton – Die hörbare Alltäglichkeit des Grauens (24. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Sueddeutsche_23_09_2010.pdf">Süddeutsche Zeitung – Das ganz normale Entsetzen (23. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Frankfurter_Rundschau-_Nutzer-Oberflaeche_fuer_die_Geschichte_22.9.2010.pdf">Frankfurter Rundschau – Feuilleton – Nutzer-Oberfläche für die Geschichte (22. Sept. 2010)</a><br>
                        </li>
                        <li>
                            <a target="_blank" href="{$download2Path}Radar_SuperPaper_Sept2010.pdf">SuperPaper – RADAR-Interview (September 2010)</a>
                        </li>
                    </ul>
                </details>

            </div>

            <div class="" in:fly="{{delay: 200}}" >

                <details >
                    <summary>Fotoarchiv</summary>

                    <div class="flex" in:fly="{{delay: 200}}" >
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Dercon_Melian_Kuenstlergespraech.jpg">
                            <img width="150px" src="{$download2Path}Dercon_Melian_Kuenstlergespraech.jpg" alt="Dercon_Melian_Kuenstlergespraech" />
                        </a>
                        <a target="_blank" href="{$download2Path}Dercon_Melian_Kuenstlergespraech-2.jpg">
                            <img width="150px" src="{$download2Path}Dercon_Melian_Kuenstlergespraech-2.jpg" alt="Dercon_Melian_Kuenstlergespraech-2" />
                        </a>
                    </div>
                    <p><i>Memory Loops</i> – Michaela Melián im Gespräch mit Chris Dercon, Direktor Haus der Kunst München, 26. September 2010, Rathausgalerie Kunsthalle, München</p>    

                    <div class="flex" in:fly="{{delay: 200}}" >
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Assmann_Hirsch_Welzer_Panel-2.jpg">
                            <img width="150px" src="{$download2Path}Assmann_Hirsch_Welzer_Panel-2.jpg" alt="Assmann_Hirsch_Welzer_Panel-2"/>
                        </a>
                        <a target="_blank" href="{$download2Path}Assmann_Hirsch_Welzer_Panel-1.jpg">
                            <img width="150px" src="{$download2Path}Assmann_Hirsch_Welzer_Panel-1.jpg" alt="Assmann_Hirsch_Welzer_Panel-1" />
                        </a>
                    </div>
                    <p><i>Zeitgenössische künstlerische Formen der Erinnerung im öffentlichen Raum</i>, Podiumsdiskussion mit Aleida Assmann, Nikolaus Hirsch und Harald Welzer, 30. September 2010, Rathausgalerie Kunsthalle, München</p>
                        
                    <div class="flex" in:fly="{{delay: 200}}" >
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Billboard_Marienplatz.jpg">
                            <img width="150px" src="{$download2Path}Billboard_Marienplatz.jpg" alt="Billboard_Marienplatz">
                        </a> <br> 
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Billboard_Odeonsplatz.jpg">
                            <img width="150px" src="{$download2Path}Billboard_Odeonsplatz.jpg" alt="Billboard_Odeonsplatz">
                        </a> <br> 
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Billboard_Dachauer_Str.jpg">
                            <img width="150px" src="{$download2Path}Billboard_Dachauer_Str.jpg" alt="Billboard_Dachauer_Str">
                        </a><br>
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Lifasssaeule_Lazarettstrasse-1.jpg">
                            <img width="150px" src="{$download2Path}Lifasssaeule_Lazarettstrasse-1.jpg" alt="Lifasssaeule_Lazarettstrasse-1">
                        </a><br>
                        <a target="_blank" href="{$download2Path}Litfasssaeule_Sonnenstrasse-2.jpg">
                            <img width="150px" src="{$download2Path}Litfasssaeule_Sonnenstrasse-2.jpg" alt="Litfasssaeule_Sonnenstrasse-2">  
                        </a><br>
                    </div>
                    <p>Werbung im öffentlichen Raum</p>    
                        
                    <div class="flex" in:fly="{{delay: 200}}" >
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Memoryloops_Schild_Hohenzollernstrasse.jpg">
                        <img width="150px" src="{$download2Path}Memoryloops_Schild_Hohenzollernstrasse.jpg" alt="Memoryloops_Schild_Hohenzollernstrasse">
                        </a><br>
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Memoryloops_Schild_Max-Joseph-Platz.jpg">
                        <img width="150px" src="{$download2Path}Memoryloops_Schild_Max-Joseph-Platz.jpg" alt="Memoryloops_Schild_Max-Joseph-Platz">  
                        </a><br>
                        <a class="mr-4 mb-2" target="_blank" href="{$download2Path}Memoryloops_Schild_Odeonsplatz.jpg">
                        <img width="150px" src="{$download2Path}Memoryloops_Schild_Odeonsplatz.jpg" alt="Memoryloops_Schild_Odeonsplatz">
                        </a><br>
                        <a target="_blank" href="{$download2Path}Memoryloops_Schild_Justizpalast.jpg">
                        <img width="150px" src="{$download2Path}Memoryloops_Schild_Justizpalast.jpg" alt="Memoryloops_Schild_Justizpalast">
                        </a><br>
                    </div>
                    <p>Hinweisschilder im Münchner Stadtraum</p>
                
                </details>
            </div>

            <div class="" in:fly="{{delay: 200}}" >

                <details >
                    <summary>Veranstaltungen zur Eröffnung</summary>

                    <h4>Eröffnung</h4>
                    <p>
                        Donnerstag, 23. September 2010, 18.00 Uhr<br>
                        Rathausgalerie Kunsthalle, München Marienplatz 8<br>
                        <br>
                        Es sprechen:<br>
                        Michael Leonhart, Stadtrat der Landeshauptstadt München in Vertretung des Oberbürgermeisters<br>
                        Dr. Hans-Georg Küppers, Kulturreferent der Landeshauptstadt München<br>
                        Dr. Johannes Grotzky, Hörfunkdirektor des Bayerischen Rundfunks<br>
                        <br>
                        Bis zum 03. Oktober 2010 wird das Kunstwerk mit verschiedenen Hörstationen in der Rathausgalerie Kunsthalle vorgestellt.<br>
                        Öffnungszeiten: Dienstag bis Sonntag: 11:00 – 19:00 Uhr<br>
                    </p>

                    <h4>Veranstaltungen</h4>
                    <p>
                        Sonntag, 26. September 2010, 11.00 Uhr<br>
                        Rathausgalerie Kunsthalle, München Marienplatz 8<br>
                        <span class="text-white"><i>Memory Loops</i> – Michaela Melián im Gespräch mit Chris Dercon, Direktor Haus der Kunst München</span><br>
                    </p>

                    <p>
                        Donnerstag, 30. September 2010, 19.00 Uhr <br>
                        Rathausgalerie Kunsthalle, München Marienplatz 8<br>
                        <span class="text-white">Podiumsdiskussion <i>Gedenk.Kunst – Zeitgenössische künstlerische Formen der Erinnerung im öffentlichen Raum</i></span><br>
                        Begrüßung durch Dr. Hans-Georg Küppers, Kulturreferent der Landeshauptstadt München<br>
                        Gäste<br>
                        Prof. Dr. Aleida Assmann, Universität Konstanz, Fachbereich Literaturwissenschaft/Anglistik<br>
                        Prof. Nikolaus Hirsch, design. Rektor Städelschule, Frankfurt am Main<br>
                        Prof. Dr. Harald Welzer, Leitung Forschungsgruppe Erinnerung und Gedächtnis am Kulturwissenschaftlichen Institut Essen<br>
                        Moderation<br>
                        Julian Doepp, BR Hörspiel und Medienkunst<br>
                    </p>

                    <h4><i>Memory Loops</i> auf Bayern 2</h4>
                    <p>
                        24. September 2010, 20.30 Uhr<br>
                        Neue Formen des Gedenkens – das Kunstprojekt <i>Memory Loops</i><br>
                        Michaela Melián im Gespräch mit Ania Mauruschat im hör!spiel!art.mix, Bayern 2<br>
                    </p>
                    <p>
                        Sendung der fünf einstündigen Erinnerungsschleifen<br>
                        Sonntags, vom 26. September bis 24. Oktober 2010, um 15.00 Uhr<br>
                        Montags, vom 27. September bis 25. Oktober 2010, um 20.30 Uhr<br>
                    </p>
                    <p>
                        Ausstrahlung des Mitschnitts der Podiumsdiskussion <br>
                        1. Oktober 2010, 20.30 Uhr im hör!spiel!art.mix, Bayern 2 <br>
                    </p>

                </details>
            </div>

        </section>

        <br>
        <br>
        <br>
        <br>
        <br>
        <br>

    </div>
</div>
