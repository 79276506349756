<!-- -------------------------------------------------------------------------------------------- -->

<script>
    import {fade} from 'svelte/transition'
    import {fly} from 'svelte/transition'
    import { quadInOut } from 'svelte/easing';
    import { download2Path } from '../Store/store.js';
</script>


<!-- -------------------------------------------------------------------------------------------- -->



<div class="info-content " in:fly="{{duration: 500, opacity: 0.0, easing: quadInOut}}">
    <div class="container" in:fly="{{delay: 100, duration: 500, y: 50, opacity: 0.0, easing: quadInOut}}">



        <section id="hoerspiel">

            <div class="md:flex" in:fly="{{delay: 0}}">
                <div class="md:w-1/2">
                    <h1>Hörspiel</h1>
                </div>
                <div class="md:w-1/2">
                </div>
            </div>

            <div class="md:flex" in:fly="{{delay: 0}}">
                <div class="md:w-1/2 md:pr-6">
                    <p>Sechs der Tonspuren, davon eine in englischer Sprache, sind einstündige Erinnerungsschleifen, die sich über den ganzen Stadtraum legen und Themenschwerpunkte haben. Alle stehen zum sofortigen Anhören oder zum Download zur Verfügung. Im September und Oktober 2010 wurden die fünf deutschen einstündigen Tonspuren als Hörspielreihe auf Bayern 2 gesendet und können nun dort im Hörspiel Pool heruntergeladen werden.</p>
                    <!-- <p>In Münchner Museen und Institutionen liegen MP3-Abspielgeräte mit allen einstündigen Hörspielen zur Ausleihe bereit: Münchner Stadtmuseum, Jüdisches Museum München, Museum Villa Stuck, Museumsshop des Lenbachhauses im Ruffinihaus, Haus der Kunst.</p> -->
                    <br>
                    <ul class="link-external list-inside">
                        <h3>
                            <li>
                                <a href="https://www.br.de/radio/bayern2/sendungen/hoerspiel-und-medienkunst/hoerspiel-pool/memory-loops102.html" target="_blank" >Zu den Podcasts auf Bayern 2</a>
                            </li>
                        </h3>
                    </ul>
                    <ul class="downloads list-inside">
                        <h3>
                            <li>
                                <a href="{$download2Path}Memory_Loops_Alle_Hoerspiele.zip">Alle Herunterladen</a>
                            </li>
                        </h3>
                    </ul>
                </div>
                <div class="md:w-1/2">
                    <ul class="downloads list-disc list-inside">
                        <li>
                            <a href="{$download2Path}Memory_Loops_-_1_-_Herzog-Max-Strasse_Herzog-Rudolf-Strasse_Antonienstrasse_Knorrstrasse.zip">Loop 1</a> 
                            <p>Herzog-Max-Straße Herzog-Rudolf-Straße Antonienstraße Knorrstraße</p>
                        </li>
                        <li>
                            <a href="{$download2Path}Memory_Loops_-_2_-_Prinzregentenplatz_Lindwurmstrasse_Hauptbahnhof_Odeonsplatz.zip">Loop 2</a> 
                            <p>Prinzregentenplatz Lindwurmstraße Hauptbahnhof Odeonsplatz</p>
                        </li>
                        <li>
                            <a href="{$download2Path}Memory_Loops_-_3_-_Ettstrasse_Maistrasse_Haar_Hackerbruecke.zip">Loop 3</a> 
                            <p>Ettstraße Maistraße Haar Hackerbrücke</p></li>
                        <li>
                            <a href="{$download2Path}Memory_Loops_-_4_-_Gaertnerplatz_Marsstrasse_Promenadeplatz_Prinzregentenstrasse.zip">Loop 4</a> 
                            <p>Gärtnerplatz Marsstraße Promenadeplatz Prinzregentenstraße</p>
                        </li>
                        <li>
                            <a href="{$download2Path}Memory_Loops_-_5_-_Pestalozzistrasse_Ettstrasse_Brienner_Strasse_Mariannenbruecke.zip">Loop 5</a> 
                            <p>Pestalozzistraße Ettstraße Brienner Straße Mariannenbrücke</p>
                        </li>
                        <li>
                            <a href="{$download2Path}ML_Druckversion_Hoerspieldownload_Website.pdf" target="_blank" >Info Hörspiele</a>
                            <p>Druckversion Hörspiel</p>
                        </li>
                    </ul>
                </div>
            </div>

        </section>

         <br>
        <br>
        <br>
        <br>
        <br>
        <br>

    </div>
</div>
