<!-- -------------------------------------------------------------------------------------------- -->

<script>
    import {fade} from 'svelte/transition'
    import {fly} from 'svelte/transition'
    import { quadInOut } from 'svelte/easing';
</script>


<!-- -------------------------------------------------------------------------------------------- -->



<div class="info-content" in:fly="{{duration: 500, opacity: 0.0, easing: quadInOut}}">
    <div class="container" in:fly="{{delay: 100, duration: 500, y: 50, opacity: 0.0, easing: quadInOut}}">


        <section id="hilfe">

            <h1>Hilfe</h1>
            <details open>
                <summary>Was sind die Hörspiele?</summary>
                <p>Sechs der Tonspuren, davon eine in englischer Sprache, sind einstündige Erinnerungsschleifen, die sich über den ganzen Stadtraum legen und Themenschwerpunkte haben. Sie können hier im Menü unter <a href="/#/hoerspiel">Hörspiel</a> direkt heruntergeladen werden. Im September und Oktober 2010 werden die fünf deutschen einstündigen Tonspuren als Hörspielreihe auf Bayern 2 gesendet und können auch dort im <a target="_blank" href="https://www.br.de/radio/bayern2/sendungen/hoerspiel-und-medienkunst/hoerspiel-pool/memory-loops102.html">Hörspiel Pool</a> heruntergeladen werden.</p>
            </details>

            <details open>
                <summary>Wie kann ich Tonspuren herunterladen?</summary>
                <p>Sie können Tonspuren herunterladen, um sie sich zum Beispiel auf Ihrem MP3-Player anzuhören:<br>
                Eine einzelne Tonspur können Sie entweder im Player oder in der Tonspuren-Liste durch Klicken auf <img src="./assets/images/icon-download.svg" style="display:inline-block; margin: 0px 4px 2px 4px;"alt="Download"> herunterladen.<br>
                Außerdem können Sie unter dem Menüpunkt Tonspuren auch alle Tonspuren auf einmal herunterladen.</p>
            </details>

            <details open>
                <summary>Wo erhalte ich weitere Informationen über Memory Loops</summary>
                <p>Informationen zum Projekt <i>Memory Loops</i> und zur Künstlerin Michaela Melián finden Sie unter <a href="/#/ueber">Über</a>.</p>
            </details>

        </section>
        
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>

    </div>
</div>
