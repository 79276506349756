<!-- -------------------------------------------------------------------------------------------- -->

<script>
    import {fade} from 'svelte/transition'
    import {fly} from 'svelte/transition'
    import { quadInOut } from 'svelte/easing';
</script>



<!-- -------------------------------------------------------------------------------------------- -->



<div class="info-content" in:fly="{{duration: 500, opacity: 0.0, easing: quadInOut}}">
    <div class="container" in:fly="{{delay: 100, duration: 500, y: 50, opacity: 0.0, easing: quadInOut}}">


        <section id="datenschutz">

            <h1>Privacy</h1>

            <p>The following text intends to inform you about the nature, scope and purpose of recording and using personal data on the online site at https://www.memoryloops.net.</p>

            <p>You can visit this online site without giving details of your person. We solely save the access data without reference to a person, such as the name of your internet service provider, the page from which you visited us or the name of the requested file. These data are evaluated solely to improve our site, but do not allow your person to be traced. We process a user’s personal data only in compliance with the pertinent data protection regulations. This means that users’ personal data are processed on visiting this online site as follows:</p>
            
            <h4>Types of personal data processed:</h4>
            <ul class=" list-disc list-inside">
                <li>Inventory data (e.g. name, address only in case of electronic contact)</li>
                <li>Contact data (e.g. e-Mail, telephone number – only in case of electronic contact)</li>
                <li>Content data (e.g. text inputs, image files, video files – only in case of electronic contact)</li>
                <li>Usage data (e.g. web pages visited, interest in content, access times)</li>
                <li>Meta/communication data (e.g. device information, IP addresses)</li>
            </ul>

            <h4>Categories of people affected</h4>
            <p>Visitors and users of the online site (referred to together below as “users”).</p>
            
            <h4>Purpose of processing</h4>
            <ul class=" list-disc list-inside">
                <li>Provision of the online site, its functions and contents</li>
                <li>Responding to inquiries and communicating with users</li>
                <li>Sicherheitsmaßnahmen</li>
                <li>Reach measurement/marketing</li>
            </ul>

            <p>As a responsible provider, we do not deploy automatic decision-making or profiling.</p>

            <h4>1. Name / address of the officer responsible for processing</h4>
            <p>
                City of Munich<br>
                Represented by the Director of the Department of Arts and Culture Anton Biebl<br>
                <br>
                Department of Arts and Culture<br>
                Department 1<br> 
                Burgstraße 4<br>
                D-80331 Munich<br>
                <br>
                Tel: +49-(0)89-233-26087<br>
                Fax: +49-(0)89-233-21269<br>
                Email: <a href="kultur.foerderung@muenchen.de">kultur.foerderung@muenchen.de</a><br>
                <br>
                Value-added tax ID number pursuant to §27a Value-added Tax Act: DE129524000
                <br>
                Statutory corporation under public law<br>
                Competent supervisory authority: government of Upper Bavaria
                <br>
                Link to imprint: <a href="http://memoryloops.net/imprint">http://memoryloops.net/imprint</a><br>
            </p>


            <h4>2. Official Data Protection Officer</h4>

            <p>
                City of Munich<br>
                Official Data Protection Officer<br>
                Burgstraße 4<br>
                D-80331 Munich<br>
                Email: <a href="mailto:datenschutz@muenchen.de">datenschutz@muenchen.de</a><br>
            </p>


            <h4>3. Determination of terms</h4>

            <p>Among others, this declaration of data protection uses the following legal terms, which are used by European issuers of directives and lawmakers in the General Data Protection Regulation (GDPR) and determined there in Art. 4 (Determination of terms) as follows:</p>

            <p>“Personal data” refers to all information relating to an identified or identifiable, natural person (referred to below as the “affected person” or “data subject”); identifiable is regarded as being a natural person, who can be identified directly or indirectly, in particular by means of assignment to an identifier, such as a name, to a code number, to locational data, to an online identifier or to one or more special features, which are an expression of the physical, physiological, genetic, psychical, financial, cultural or social identity of this natural person.</p>     

            <p>“Processing” refers to each procedure executed or each series of such procedures, which are connected to personal data, either with or without the help of automated processes. Examples of these are recording, capturing, organising, arranging, saving, adapting or changing, reading out, polling, using, disclosure through transmission, distribution or another form of provision, comparing or linking, restricting, deleting or destruction.</p>

            <p>“Responsible officer” refers to the natural person, legal entity, authority, institution or other office, which decides alone or together with others on the purposes and means of the processing of personal data. If the purpose and means of this processing are stipulated by the law of the Union or the law of the Member States, the responsible officer or certain criteria for his or her nomination can be drawn from the law of the Union or the law of the Member States.</p>
            
            <p>“Consent” from the affected person refers to every voluntary notice of intention issued for the determined case, in an informed and unmistakeable manner, in the form of a declaration or some other clearly confirming act, with which the affected person lets it be known that they agree with the processing of personal data affecting them.</p>        


            <h4>4. Purposes and legal basis for processing personal data</h4>

            <p>The purpose of processing is to fulfil the public duties assigned to us by lawmakers.</p>
            <p>Unless stated otherwise, the legal basis for processing your data results from Art. 4 Para. 1 of the Bavarian Data Protection Act (BayDSG) in conjunction with Art. 6 Para. 1 Letter e of the General Data Protection Regulation (GDPR). This legislation entitles us to process the data necessary to fulfil a remit to which we are subject.</p>
            <p>If you have given your consent to processing, data processing is based on Art. 6 Para. 1 Letter a GDPR.</p>
            <p>The legal basis for data processing to safeguard our legitimate interests is Art. 6 Para. 1 Letter f) GDPR.</p>


            <h4>5. Collaboration with order processors and third parties</h4>

            <p>Insofar as we disclose data to other persons and enterprises (order processors or third parties) in the course of our data processing, transmit data to them or otherwise grant them access to data, this is done on the basis of legal permission, on the basis of your consent, because this is foreseen by a legal obligation or on the basis of our legitimate interests (e.g. when deploying a hosting provider, etc.). If we commission third parties to process personal data on the basis of a so-called “order processing contract”, this is done on the basis of Art. 28 GDPR.</p>
            

            <h4>6. Transmissions to third countries</h4>

            <p>Insofar as we process data in a third country (i.e. outside the European Union (EU) or the European Economic Area (EEA)) or if this is done in the context of commissioning third-party services by disclosing or transmitting data to third parties, this only happens if it is to fulfil our (pre)contractual duties, on the basis of your consent, due to a legal obligation or on the basis of our legitimate interests. On the proviso of legal or contractual permission, we process or have the data processed in a third country only if the particular prerequisites of Art. 44 ff. GDPR are fulfilled. In other words, processing is done on the basis of special guarantees, such as the officially recognised establishment of a level of data protection corresponding to that in the EU (e.g. for the USA through the “Privacy Shield”) or in observance of officially recognised, special contractual obligations (so-called “standard contractual clauses”).</p>


            <h4>7. Rights of affected persons</h4>

            <p>The prevailing legislation concerning data protection provides you with comprehensive rights as the data subject (rights of information and intervention) vis-à-vis the responsible officer with regard to the processing of your personal data. We inform you of these rights below:</p>

            <p><u>Right of information in accordance with Art. 15 GDPR:</u> 
            <br>the affected person or data subject has the right to demand confirmation from the responsible officer of whether personal data affecting them have been processed. If this is the case, there is a right to find out about these personal data and to the following information:</p>

            <ul class=" list-inside">
                <li>a) the purposes of processing</li>
                <li>b) the categories of personal data that have been processed</li>
                <li>c) the recipients or categories of recipients to whom personal data were or will be disclosed, in particular in the case of recipients in third countries or in the case of international organisations</li>
                <li>d) if possible, the duration planned for storing personal data or, if this is not possible, the criteria for determining this duration</li>
                <li>e) the existence of a right to correction or deletion of personal data affecting them, to restrict processing by the responsible officer or a right of objection to this processing</li>
                <li>f) the existence of a right of complaint to a supervisory authority</li>
                <li>g) if personal data have not been recorded from the affected person, all information available on the origin of the data</li>
                <li>h) the existence of automated decision-making, including profiling, in accordance with Article 22 Paragraphs 1 and 4 GDPR and — at least in these cases — meaningful information on the logic involved, on the extent and on the intended effects of such processing for the affected person.</li>
            </ul>

            <br> 

            <p><u>Right to correction in accordance with Art. 16 GDPR:</u> 
            <br>you have a right to immediate correction of incorrect data affecting you and/or completion of your incomplete data saved by us;</p>
            
            <p><u>Right to deletion in accordance with Art. 17 GDPR:</u> 
            <br>you have the right to demand the deletion of your personal data if the prerequisites of Art. 17 Para. 1 GDPR are fulfilled. However, this right does not exist if processing is necessary to exercise the right of free expression and information, to fulfil a legal obligation, for reasons of the public interest or to pursue, exercise or defend against legal claims</p>

            <p><u>Right to restrict processing in accordance with Art. 18 GDPR:</u>
            <br>you have the right to demand a restriction to the processing of your personal data, for as long as the correctness of the data you have disputed is being verified, if you reject a deletion of your data due to inadmissible data processing and instead demand the restriction of the processing of your data, if you require your data to pursue, exercise or defend against legal claims, after we no longer require these data once the purpose has been achieved or if you have lodged an objection because of your particular situation, as long as it has not been established whether these grounds are outweighed by our legitimate reasons</p>
            
            <p><u>Right to information in accordance with Art. 19 GDPR:</u>
            <br>if you have pursued the right to correction, deletion or restriction to processing against the responsible officer, the latter is obliged to notify all recipients, to whom the personal data affecting you have been disclosed, of this correction or deletion of the data or restriction of the processing, unless this proves to be impossible or is associated with an unreasonable amount of effort. You have the right to be informed about these recipients.</p>
            
            <p><u>Right to data portability in accordance with Art. 20 GDPR:</u>
            <br>you have the right to receive the personal data that you have provided to us in a structured, common and machine-readable format, or to demand that they be transmitted to another responsible office, insofar as this is technically feasible</p>
           
            <p><u>Right to revoke consent issued in accordance with Art. 7 Para. 3 GDPR:</u>
            <br>you have the right to revoke consent issued to process data at any time, with affect for the future. In case of revocation, we will delete the data concerned without delay, unless further processing can be supported by a legal basis for processing without consent. A revocation of consent does not affect the legitimacy of the processing performed up to the date of the revocation of consent</p>
           
            <p><u>Right to complaint in accordance with Art. 77 GDPR:</u> 
            <br>if you believe that the processing of personal data affecting you violates the GDPR, you have the right – without prejudice to any other legal remedy under administrative law or the law of court – to complain to a supervisory authority, in particular in the Member State of your residence, your workplace or the place of the alleged violation.</p>

            <p><u>Right of objection in accordance with Art. 21 GDPR:</u> 
            <br>you can object to the processing of personal data affecting you in future at any time pursuant to Art. 21 GDPR. You can object, in particular, to the processing of your personal data for purposes of direct advertising.</p>


            <h4>8. Right of complaint to the supervisory authority</h4>
            
            <p>You furthermore have a right to complain to the Bavarian State Authority for Data Protection. This can be reached under the following contact data:</p>        
            <p>
                Postal address: P. O. Box 22 12 19, D-80502 Munich<br>
                Address: Wagmuellerstrasse 18, D-80538 Munich<br>
                <br>
                Phone: +49-(0)89 212672-0<br>
                Fax: +49-(0)89-212672-50<br>
                <br>
                Email: <a href="mailto:poststelle@datenschutz-bayern.de">poststelle@datenschutz-bayern.de</a><br>
                Internet: <a href="https://www.datenschutz-bayern.de">https://www.datenschutz-bayern.de</a>
            </p>


            <h4>9. Cookies</h4>
            
            <p>We use cookies to make visits to our online site as attractive as possible and to enable the use of certain functions. Cookies are text files, which are deposited and stored by an internet browser on a user’s computer system. Various details can be saved in the cookies. A cookie primarily serves to store the details of users (or the device on which the cookie is stored) during or even after their visits to an online site. Temporary cookies, session cookies or transient cookies refer to cookies, which are deleted after a user leaves an online site and closes his or her browser. Such a cookie can store, for example, the content of a goods trolley in an online shop or a login status. Permanent or persistent cookies refer to cookies, which remain stored even after the browser has been closed. For example, a login status can be stored if a user searches for this several days later. The interests of a user can likewise be stored in such a cookie, which are then used for reach measurement or marketing purposes. Third-party cookies refer to cookies offered by providers other than the officer responsible for operating the online site (otherwise – if it is only the officer’s cookies– one speaks of first-party cookies).</p>
            <p>We can deploy temporary and permanent cookies and inform you of this in our declaration of data protection as follows:</p>
            <p>If users do not want cookies to be saved on their computers, they need to deactivate the appropriate option in the system settings of their browser. Cookies already saved can be deleted in the browser’s system settings. The exclusion of cookies can lead to a restriction of functions on this online site.</p>
            
            
            <h4>10. Making contact</h4>
            
            <p>Information that you send to us unencoded by electronic post (e-Mail) can possibly be read by third parties along the transmission path. As a rule, we are not able to verify your identity and therefore do not know who is hiding behind an e-Mail address. Legally secure communication through a simple e-Mail can therefore not be guaranteed. We – like many other e-Mail providers – deploy filters to counter unwanted advertising (“SPAM filters”). In rare cases, these filters can falsely categorise normal e-Mails as undesired advertising and automatically delete these. E-Mails containing damaging programs (“viruses”) are automatically deleted by us in all circumstances.</p>
            <p>If you want to send us messages that are worthy of protection, we recommend that you encode and sign these. This prevents unauthorised reading and falsification along the transmission path. Alternatively, send us the message by conventional post. Please also tell us whether we can send you encoded e-Mails in response to your messages and by what means. If you are unable to receive encoded e-Mails, please tell us your postal address so that we can answer your messages worthy of protection.</p>    
            <p>Personal data are recorded if you contact us (e.g. e-Mail). Such data are saved and used exclusively for the purpose of answering your inquiry and/or for making contact and the associated technical administration. The legal basis for processing the data is our legitimate interest in answering your inquiry in accordance with Art. 6 Para. 1 Letter f GDPR. If the aim of your contact is to conclude a contract, an additional legal basis for processing is then Art. 6 Para. 1 Letter b GDPR. After subsequently processing your inquiry, your data are deleted, provided that it is apparent from the circumstances that the matter concerned has been definitively clarified and insofar as this is not contradicted by any legal duties of retention.</p>


            <h4>11. Deletion of data / restriction of processing</h4>
            
            <p>Personal data processed by us are deleted pursuant to Art. 17 and 18 GDPR or their processing is restricted. Insofar as not specifically stated otherwise in this declaration of data protection, data saved by us are deleted as soon as they are no longer required for their intended purpose and their deletion is not contradicted by any legal duties of retention. For example, the latter applies to data, which need to be retained for reasons of commercial or fiscal legislation (e.g. § 257 HGB, § 147 Para. 1 AO – period of retention of up to 10 years). If data are not deleted because they are required for other, legally admissible purposes, their processing is then restricted. In other words, the data are blocked and not processed for other purposes.</p>


            <h4>12. Hosting / recording access data and logfiles</h4>
            
            <p>The hosting services that we order from our hosting provider serve the provision of the following services for our online site: infrastructure and platform services, computing capacity, storage place and database services, security services and technical maintenance, which we use for the purpose of operating this online site. We and/or our hosting provider hereby process contact data (in case of e-Mail contact: e-Mail address), usage data, meta and communication data from users of this online site on the basis of our legitimate interests in providing this online site efficiently and securely in accordance with Art. 6 Para. 1 Letter f GDPR in conjunction with Art. 28 GDPR (conclusion of an order processing contract).</p>
            <p>We and/or our hosting provider record a series of general data and information each time our online site is called-up by an affected person or by an automated system. These general data and information are saved in the server’s logfiles. The following can be recorded: (1) the browser types and versions used, (2) the operating system used by the accessing system, (3) the internet page from which an accessing system reaches our internet site (so-called “referrer”), (4) the sub-web pages controlled through a system accessing our internet site, (5) the date and time of access to the internet site, (6) an internet protocol address (IP address), (7) the internet service provider of the accessing system and (8) other, similar data and information that serve to defend against danger if our information technology systems are attacked.</p>    
            <p>In using this general data and information, we and/or our hosting provider do not draw any conclusions about the affected person. This information is rather required for the following purposes: (1) to correctly deliver the contents of our internet pages, (2) to optimise the contents of our internet pages and the advertising for this, (3) to ensure that our information technology systems and the engineering of our internet site function properly at all times and (4) to provide the necessary information to the criminal investigation authorities in case of a cyber attack. Data from the server logfiles are stored separately from any personal data stated by an affected person.</p>
            <p>This logfile information is saved for a maximum of 60 days and subsequently deleted.</p>

            <!--
            <h4>13. Matomo web analysis service</h4>
            
            <p>We process data on our web site using the Matomo web analysis software (www.matomo.org), a service provided by InnoCraft Ltd., 150 Willis St, 6011 Wellington, New Zealand (“Matomo”), on the basis of our legitimate interest in the statistical analysis of user behaviour for the purpose of optimisation and marketing in accordance with Art. 6 Para. 1 Letter f GDPR.</p>
            <p>The data recorded with the Matomo technology (including your pseudonymised IP address) are processed solely on our servers and are not forwarded to third parties. Pseudonymised usage profiles can be compiled and evaluated from these data for the aforesaid purpose. Cookies can also be deployed for this purpose. The information generated by the cookie in a pseudonymised user profile is not used to personally identify the visitor to this web site and it is not merged with personal data concerning the bearer of the pseudonym.</p>
            <p>If you do not agree with the storage and evaluation of these data from your visit, you can then object to their storage and use at any time by clicking on the link below.</p>
            <p>Click here to object to storage.</p>
            <p>In this case, a so-called opt-out cookie will be deposited in your browser. As a consequence, Matomo will not record any session data. Please note that the consequence of completely deleting your cookies is that the opt-out cookie will also be deleted and you may possibly have to re-activate it.</p>


            <h4>14. Social Media</h4>
            
            <p>Social media switches (“Like” buttons) are not integrated directly, but rather through the “Shariff” open source data protection project. This ensures that the City of Munich cannot transmit any data to servers of social media providers if an internet page is called-up and read.</p>
            <p>Apart from this, there are just simple links, which lead to a relevant web site placed there (e.g. fan page of the City of Munich).</p>
            <p>The purpose and scope of recording data and the further processing and use of the data by Facebook, Google, Twitter and other social media providers, your rights in this respect and the setting options to protect your private sphere can be found in the privacy policies of the respective social media provider.</p>


            <h4>(ALTERNATIVE) 14. Online presence in social media</h4>
            
            <p>We are active in the Facebook social network, which is operated by Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland (“Facebook”).</p>
            <p>For your own security, do not enter any sensitive data in public communications through the social media.</p>
            <p>Please note that we do not have any influence on the terms of use of the social network. We expressly point out to you that Facebook permanently stores data outside Germany and uses them for commercial purposes. We are unable to identify the scope and the duration for which the data are stored. The purpose and scope of recording data and the further distribution and use of the data by Facebook, the rights in this respect and the setting options to protect the user’s private sphere can be seen in Facebook’s privacy policy: https://www.facebook.com/about/privacy/.</p>
            <p>We therefore recommend that you check the personal data you provide to social networks carefully. For our part, we treat your data with the greatest care. However, we do not accept any liability for the behaviour of operators of social networks or for third parties.</p>

            -->

            <h4>13. Security measures</h4>
            
            <p>We take organisational, contractual and technical action to warrant security in line with the state-of-the-art, to ensure that the provisions of data protection laws are complied with and to thereby protect the data processed by us from chance or malicious manipulation, loss, destruction or against the access of unauthorised parties.</p>
            <p> If you have any questions regarding the recording, processing or use of your personal data, if you require information, correction, blockage or deletion of data and to revoke any consent issued, please contact the e-Mail address stated in the Impressum.</p>
            
        </section>


        <br>
        <br>
        <br>
        <br>
        <br>
        <br>

    </div>
</div>
