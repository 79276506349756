<!-- -------------------------------------------------------------------------------------------- -->

<script>
    import {fade} from 'svelte/transition'
    import {fly} from 'svelte/transition'
    import { quadInOut } from 'svelte/easing';
</script>


<!-- -------------------------------------------------------------------------------------------- -->



<div class="info-content" in:fly="{{duration: 500, opacity: 0.0, easing: quadInOut}}">
    <div class="container" in:fly="{{delay: 100, duration: 500, y: 50, opacity: 0.0, easing: quadInOut}}">


        <section id="hilfe">

            <h1>Help</h1>
            <details open>
                <summary>What are the radio plays?</summary>
                <p>Five audio tracks in German and one in English have been recorded as one-hour <i>Memory Loops</i> that cover the whole city area with a focus on different subjects.They can be found under <a href="/#/radioplay">‘Radio Play’</a> in this menu and downloaded directly. The German one-hour audio tracks will be broadcast as a radio series on Bayern 2 in September and October 2010, and can also be downloaded as <a target="_blank" href="https://www.br.de/radio/bayern2/sendungen/hoerspiel-und-medienkunst/hoerspiel-pool/memory-loops102.html">podcasts.</a> </p>
            </details>

            <details open>
                <summary>How can I download the audio tracks?</summary>
                <!-- There is a way to download the audio tracks so that you can listen to them on your MP3 player, for example:<br> -->
                <p>You can download one particular audio track by clicking on the icon <img src="./assets/images/icon-download.svg" style="display:inline-block; margin: 0px 4px 2px 4px;"alt="Download"> either in the player or in the audio track list.<br>
                In addition, you can download all audio tracks at once by clicking on <a href="http://www.memoryloops.net/en/download/Memory_Loops_-_1_-_English_Radio_Play"> Audio Tracks <img src="./assets/images/icon-download.svg" style="display:inline-block; margin: 0px 4px 2px 4px;"alt="Download"></a></p>
            </details>

            <details open>
                <summary>Where can I find more information on the <i>Memory Loops</i>?</summary>
                <p>Further details on the <i>Memory Loops</i> project and the artist Michaela Melián can be found under <a href="/#/about">About</a> in the menu.</p>
            </details>

        </section>
        
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>

        
    </div>
</div>
