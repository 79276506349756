
<!-- ------------------------------------------------------------------ -->

<svelte:options accessors/>
<!-- <svelte:window on:resize={(e)=>onWindowResize(e)} /> -->

<!-- ------------------------------------------------------------------ -->

<script>
	import { onMount, getContext } from "svelte"
	
	import { 
		lang, sortBy, loopListOpen, storedLoops
	} from "../Store/store.js"

	import { fly } from 'svelte/transition';
	import { quintOut } from 'svelte/easing';

	import LoopList from "./LoopList.svelte";
	import {stopAll} from "./LoopPlayer.svelte";
	
	let app = getContext( 'app' );

	const Tonspuren = {'de':'Tonspuren', 'en':'Audio Tracks'}
	


  // function myTestHandler(e) {
		// console.log("('------- >>>", e )
		// console.log(">>>", e.detail.text )
  // }

  // function onWindowResize(e) {
	// 	// console.log("('------- >>>", e )
	// 	resize_LoopList_MoveableControlBox(e)
  // }


	onMount(()=>{
		// setEventHandling()
	});


	function setEventHandling() {
		console.log("|----- setEventHandling()")

		function stopPropagation(e) {
			console.log("|----- test()")
			e.stopPropagation();
		}
		function preventDefault(e) {
			e.preventDefault();
		}
		// enable browsed-based mouse-wheel and touch-scrolling support:
		// addEventListener("wheel", stopPropagation, true);
		// addEventListener("mousewheel", stopPropagation, true);
		// addEventListener("DOMMouseScroll", stopPropagation, true);
		addEventListener(
			"touchstart",
			function (event) {
				if (
					krpano.device.ios &&
					window.innerHeight == krpano.display.htmltarget.offsetHeight
				) {
					/* avoid the iOS 'overscrolling' for fullpage viewers */ var bs =
						document.body.parentNode.style;
					bs.position = "fixed";
					bs.top = 0;
					bs.left = 0;
					bs.right = 0;
					bs.bottom = 0;
				}
				krpano.control.preventTouchEvents = false;
				event.stopPropagation();
			},
			true
		);
		addEventListener(
			"touchend",
			function (event) {
				krpano.control.preventTouchEvents = true;
				event.stopPropagation();
			},
			true
		);
		addEventListener("gesturestart", preventDefault, true);
	}

	// function onLoadHandler(e) {
	// 	console.log("onLoadHandler()", e)
	// }

	// function onLoad(e) {
	// 	target = e.target;
	// 	setTimeout(() => {
	// 		console.log("on: Load", e)
	// 	});
	// }
	// function onMouseOver(e) {
	// 	target = e.target;
	// 	setTimeout(() => {
	// 		console.log("on: MouseOver",  e)
	// 	});
	// }
	// function onMouseOut(e) {
	// 	target = e.target;
	// 	setTimeout(() => {
	// 		console.log("on: MouseOut")
	// 	});
	// }


</script>


<!-- ------------------------------------------------------------------ -->

				<!-- on:click="{()=>app.setLoopActive(null)}"  -->
				<!-- on:click="{()=>app.setLoopActive(null)}"  -->

<div id="loopListContainer" class="loopListContainer cursor-move " transition:fly="{{duration: 300, y: 0, opacity: 0, easing: quintOut}}" >
<!-- <div id="loopListContainer" class="loopListContainer cursor-move sm:right-0 mr-4 ml-4 mt-24 sm:mt-8 " bind:this={target} on:mouseover|stopPropagation|preventDefault={()=>onLoadHandler("aaa")} on:load={()=>onLoadHandler("aaa")} transition:fly="{{duration: 300, y: 0, opacity: 0, easing: quintOut}}" > -->
  <header class="header flex">
    <div class="flex-1 self-center text-white font-bold head-title">{Tonspuren[$lang]}</div>
    <div class="flex text-sm ">
      <button 
				on:click="{()=>sortBy(['place'])}" 
				on:click="{()=>stopAll()}" 
				class="text-xs text-gray999 hover:text-white rounded-sm ml-2 mr-0 px-1 py-1 bg-gray111 hover:bg-gray222">abc</button>
	  	<div class="self-center px-0 mx-0">/</div>
      <!-- <button on:click="{()=>sortBy(['file','pin.id'])}" class="text-xs text-gray999 hover:text-white rounded-sm ml-0 mr-1 px-1 py-1 bg-gray111 hover:bg-gray222">123</button> -->
      <button 
				on:click="{()=>sortBy(['place'])}" 
				on:click="{()=>sortBy(['pin.id','file'])}" 
				class="text-xs text-gray999 hover:text-white rounded-sm ml-0 mr-1 px-1 py-1 bg-gray111 hover:bg-gray222">123</button>
      <button on:click="{()=>app.toggleLoopList()}" class="rounded-sm ml-0 py-1 px-2 bg-gray111 hover:bg-gray222">
				<svg height="16" viewBox="0 0 12 12" width="16" xmlns="http://www.w3.org/2000/svg"><g style="stroke:#979797;stroke-width:1.7;fill:none;fill-rule:evenodd;stroke-linecap:round;stroke-linejoin:bevel" transform="translate(3 3)"><path d="m0 0 6 6" transform="matrix(-1 0 0 1 6 0)"/><path d="m0 0 6 6"/></g></svg>
      </button>
    </div>
  </header>
  <div class="content" on:mouseleave|self|stopPropagation='{()=>app.setLoopFocus()}' >
  	<LoopList/>
  </div>
</div>
      <!-- <button on:click="{()=>sortBy(['file'])}" class="text-xs text-gray999 hover:text-white rounded-sm ml-0 mr-1 px-1 py-1 bg-gray111 hover:bg-gray222">123</button> -->
		<!-- on:mouseout='{(event) => { console.log("mouseout content", event.target.id) }}' -->

<!-- ------------------------------------------------------------------ -->

<style>
	.target2 {
		width: 100px;
		height: 100px
	}
	.head-title {
		font-family: "GraphikBold", sans-serif !important;
		font-size: 1.20rem !important;
		font-weight: 200 !important;
	}
	.loopListContainer {
		z-index: 1000;
		position: absolute;
		color: #ccc;

		/* left: 1rem; */
		/* top: 2rem; */
		/* right: 2rem; */
		/* margin-top: 0px; */
		padding: 70px 1rem 1rem .5rem;

		/* width: 300px; */
		/* min-width: 275px; */
		/* max-width: 600px; */
		height: 100%;
		width: 100%;
		/* height: 55vh; */
		/* min-height: 30vh; */
		/* max-height: 90vh; */

		background-color: black;
		border: 2px solid rgb(38, 38, 38);
		border-radius: 10px;
		box-shadow: 0 10px 15px -3px rgba(0,0,0,.3),0 4px 6px -2px rgba(0,0,0,.35);
	}
	/* if Phone */
	@media (max-width: 639px) {
		.loopListContainer {
			/* width: 93vw !important; */
			/* min-width: 0px !important; */
			/* max-width: 1000px !important; */
		}
	}

	.header {
		position: absolute;
		top: 0px;
		left: 0; 
		right: 0;
		height: 60px;
		padding: 1.5rem 1rem;
		background-color: rgb(0, 0, 0);
		/* color: white; */
		border-radius: 10px;
		align-self: center;
		@apply text-gray666;
	}
	.content {
		padding-top: 0rem;
		height: 100%;
		width: 100%;
		overflow: auto;
	}
	button {
		align-self: center;
		/* padding: 7px 7px; */
	}
</style>